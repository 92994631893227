import { Col, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { FaEye, FaPencil, FaRegCalendarCheck, FaTrash } from 'react-icons/fa6'
import logo from '../../images/logo.png';
import ImageBox from '../ImageBox';
import { CreditObject} from '../../store/Store';
import {  filterPrice} from '../Alert';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PiPercentFill } from 'react-icons/pi';
import { GiPayMoney } from 'react-icons/gi';
interface MultipleSelectProps {
    selected: number[];
    setselected: (setvalue: number[]) => void;
    number: number;
    data:CreditObject;
    setnumber: (setvalue: number) => void;
    deleteHome: (setvalue: number) => void;
    unActiveHome: (setvalue: number) => void;
  
  }
const CardClientHomeCreditMini: React.FC<MultipleSelectProps>=({data, selected, setselected,number, setnumber, deleteHome, unActiveHome})=>{
    
    const [selected_bol, setselected_bol]=useState(selected.filter(x=>x===data.id).length!==0)
    const {t}=useTranslation()

    const handleSelect=(id:number)=>{
        var a=selected
        a.push(id)
        setselected(a)
        setselected_bol(true)
        setnumber(number+1)
    }
    const handleunSelect=(id:number)=>{
        var a=selected
        var b=a.findIndex(x=>x===id)
        a.splice(b, 1)
        setselected(a)
        setselected_bol(false)
        setnumber(number+1)
    }
    useEffect(()=>{
      setselected_bol(selected.filter(x=>x===data.id).length!==0)
    }, [selected])
   
  return (
    <Col className='mini_card_col' lg={8} md={12} sm={24}>
        
        <div className='card card_mini'>
            {data.status===1?<div className='client_card_text active_text'/>:<div className='client_card_text denger_text'/>}
            
            {/* <div className="lenta_card">
                <h1>TOP</h1>
            </div> */}
            <div className='card_mini_img card_img'>
            <ImageBox  alt='image' src={data.image!=null && data.image.image!=null?data.image.image:logo} />
            </div>
            <div className='card_mini_text'>
                <div className='card_mini_text_head'>
                <h1>{data.name!=null?data.name:''}</h1>
                <div className='card_mini_address'>
                <div className='card_mini_item card_item'>
                        <span><FaRegCalendarCheck /></span>
                        <p>{t("credit_month_credit")}: {data.month_min+(data.month_max===data.month_min || data.month_max===null?"":" - "+data.month_max)+" oy"}</p>
                        </div>
                <div className='card_mini_item card_item'>
                        <span><PiPercentFill /></span>
                        <p>{t("percent_credit")}: {filterPrice(data.p_min)+(data.p_max===data.p_min || data.p_max===null?"":" - "+filterPrice(data.p_max))+" %"}</p>
                        </div>
                        <div className='card_mini_item card_item'>
                        <span><GiPayMoney /></span>
                        <p>{t("start_payment_credit")}: {filterPrice(data.init_pay_percent_min)+(data.init_pay_percent_max===data.init_pay_percent_min || data.init_pay_percent_max===null?"":" - "+filterPrice(data.init_pay_percent_max))+" %"}</p>
                        </div>
                    
                </div>
                <div className='card_mini_price card_price'>
                <p>{filterPrice(data.summ_min)+" so'm - "+filterPrice(data.summ_max)+t(t(" so'm"))}</p>
                    {data.bonus_month!==null && data.bonus_month!==0?<span className='card_time'>{t("bonus_month_credit")}: {data.bonus_month+" oy"}</span>:<span className='card_time'><span className='white_text'>.</span></span>} 
                
                    
                    {/* <span>Aktiv boshlanish: 12-04-2024</span>
                    <span>Aktiv tugashi: 22-05-2024</span> */}
                    <span>{t("visit_number")}: {data.viewed!==null?data.viewed:0}</span>
                </div>
                </div>
                
            </div>
            <div className='card_btns'>
                <div className='card_btns_edit'>
                    <Link to={"/client/home/credit/edit/"+data.id} className='edit_btn'><div><FaPencil /></div></Link>
                    <Link to={"/product/home/credit/"+data.id} className='edit_btn watch_btn'><div><FaEye /></div></Link>
                    <button className='delete_btn' onClick={()=>{deleteHome(data.id)}}><div><FaTrash /></div></button>
                </div>
                {data.status!==1?selected_bol?<Tooltip placement="bottom" title={t("delete_choose")}>
                <button  onClick={()=>{handleunSelect((data.id))}} className='no_active_btn btn_client'><div>{t("delete_choose")}</div></button>
                </Tooltip>:<Tooltip placement="bottom" title={t("choose_add")}>
                <button onClick={()=>{handleSelect((data.id))}} className='active_btn btn_client'><div>{t("choose_item")}</div></button>
                </Tooltip>: <Tooltip placement="bottom" title={t("alert_active")}>
                <button onClick={()=>unActiveHome(data.id)} className='no_active_btn btn_client'><div>{t("stop_active")}</div></button>
                </Tooltip>}
                
                
               
            </div>
        </div>
    </Col>
  )
}
export default CardClientHomeCreditMini