import { Col, Row, Tooltip } from 'antd'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import SimpleSelect from '../../../../components/SimpleSelect'
import { closeSelect} from '../../../../components/Data'
import SimpleInput from '../../../../components/SimpleInput'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill/dist/quill.core.css';
import SimpleInputText from '../../../../components/SimpleInputText'
import { IoCloseCircleSharp } from 'react-icons/io5'
import { TypeObject, useStore } from '../../../../store/Store'
import { FaTriangleExclamation } from 'react-icons/fa6'
import { dangerAlert, formatPhoneNumber, scrollDivToTop } from '../../../../components/Alert'
import { httpGetRequest, httpPatchRequest, httpPostRequest } from '../../../../host/Host'
import { useNavigate, useParams } from 'react-router-dom'
import SimpleInputPhone from '../../../../components/SimpleInputPhone'
import { useTranslation } from 'react-i18next'
export default function CarAnnoucmentCreate() {
    const {region_data, district_data, setdistrict_data, 
     kuzov_data,
      engine_type_data,
      b_type_data,
      transmission_data,
      drive_unit_data,
      color_data,
      color_state_data,
      cmtype_data
    
    }=useStore()
    const [transmission, settransmission]=useState(0)
    const [drive_unit, setdrive_unit]=useState(0)
   
    const [color, setcolor]=useState(0)
    const [color_state, setcolor_state]=useState(0)
    const [ctype, setctype]=useState(0)
    const [cmtype, setcmtype]=useState(0)
    const [b_type, setb_type]=useState(0)
    const [model, setmodel]=useState(0)
    const [position, setposition]=useState(0)
    const [required, setrequired]=useState(false)
    const [kuzov, setkuzov]=useState(0)
    const [engine_type, setengine_type]=useState(0)
    const [probeg, setprobeg]=useState<number|string>('')
    const [capacity, setcapacity]=useState<number|string>('')
    const [name, setname]=useState('')
    const [agreed, setagreed]=useState(false)
    const [autosalon, setautosalon]=useState(false)
    const [rent, setrent]=useState(false)
    const [long_term_pay, setlong_term_pay]=useState(false)
    const [number, setnumber]=useState(1)
    const [images, setimages]=useState<File[]>([])
    const [video, setvideo]=useState<File[]>([])
    const [region, setregion]=useState(0)
    const [image, setimage]=useState<number|null>(null)
    const [district, setdistrict]=useState(0)
    const [facebook, setfacebook]=useState('')
    const [instagram, setinstagram]=useState('')
    const [website, setwebsite]=useState('')
    const [telegram, settelegram]=useState('')
    const [phone, setphone]=useState('')
    const [price_uzs, setprice_uzs]=useState<number|string>('')
    const [price_usd, setprice_usd]=useState<number|string>('')
    const [year, setyear]=useState<number|string>('')
    const [model_data, setmodel_data]=useState<TypeObject[]>([])
    const [ctype_data, setctype_data]=useState<TypeObject[]>([])
    const [position_data, setposition_data]=useState<TypeObject[]>([])
    const [additional, setadditional] = useState<string>('');
   
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const videoInputRef = useRef<HTMLInputElement | null>(null);
    const {setloader, user}=useStore()
    const navigate=useNavigate()
    const {id}=useParams()
    const {t} =useTranslation()
    useEffect(()=>{
      if(user!=null){
        setphone(formatPhoneNumber(user.phone))
        settelegram(user.telegram)
        setfacebook(user.facebook)
        setwebsite(user.website)
        setinstagram(user.instagram)
      }
    }, [user])
    const onFinish=async()=>{
        if(phone!=null){
          var phone_new=phone.replaceAll("_", "")
        }else{
          var phone_new=""
        }
        
        if(name.length!==0 && phone_new.length===19 && region!==0 && district!==0 && price_uzs!=='' && price_usd!=='' && cmtype!==0 && ctype!==0 && model!==0){
          if(images.length===0){
            dangerAlert(t("error_image"))
          }else{
            setloader(true)
            var data={
              name,
              price_uzs:String(price_uzs),
              price_usd:String(price_usd),
              probeg:String(probeg).length===0?null:String(probeg),
              capacity:String(capacity).length===0?null:String(capacity),
              year:String(year).length===0?null:String(year),
              agreed:agreed?'true':'false',
              autosalon:autosalon?'true':'false',
              rent:rent?'true':'false',
              long_term_pay:long_term_pay?'true':'false',
              phone,
              b_type:b_type!==0?String(b_type):null,
              instagram:instagram.length===0?null:instagram,
              facebook:facebook.length===0?null:facebook,
              website:website.length===0?null:website,
              telegram:telegram.length===0?null:telegram,
              region:region!==0?String(region):null,
              district:district!==0?String(district):null,
              transmission:transmission!==0?String(transmission):null,
              drive_unit:drive_unit!==0?String(drive_unit):null,
              color:color!==0?String(color):null,
              engine_type:engine_type!==0?String(engine_type):null,
              color_state:color_state!==0?String(color_state):null,
              ctype:ctype!==0?String(ctype):null,
              cmtype:cmtype!==0?String(cmtype):null,
              model:model!==0?String(model):null,
              position:position!==0?String(position):null,
              kuzov:kuzov!==0?String(kuzov):null,
              additional,
              video:video.length===0?null:video[0]
            }
            const formData = new FormData();
            for (const [key, value] of Object.entries(data)) {
              if (value !== null) {
                formData.append(key, value);
              }
            }
            
            try{
              var res=await httpPostRequest(`/cars/create/`, formData)
              if(images.length!=0){
                await Promise.all(images.map(async(item, key:number)=>{
                  var config=new FormData
                  config.append('image', item)
                  config.append('car', res.data.id)
                  config.append('main', key === image ? 'true' : 'false')
                  try{
                    var res1=await httpPostRequest(`/cars/images/create/`, config)
                    if(image!==null && key===image){
                      try{
                        var res2=await httpPatchRequest(`/cars/${res.data.id}/update/`, {image:res1.data.id})
                      }catch(err2){
                        console.log(err2)
                      }
                      
                    }
                  }catch(err1){
                    console.log(err1)
                  }
                }))
                setloader(false)
                navigate("/client/car/car")
              }
            }catch(err){
              setloader(false)
              dangerAlert(t("Error"))
              console.log(err)
            }
          
          }
         
        }else{
          dangerAlert(t("error_not_full"))
          setrequired(true)
          scrollDivToTop()
        }
    }
   
    const handleEditorChange = (content: string) => {
        setadditional(content);
      };
    const handlechange=()=>{

    }
   const handleCloseSelect=(e: React.MouseEvent<HTMLDivElement>)=>{
     
        if (e.target instanceof HTMLElement && e.target.className.indexOf("select")===-1 && e.target.parentElement instanceof HTMLElement && e.target.parentElement.className.indexOf("select")===-1) {
          // Handle the body click here
          closeSelect("a");
        }
       }
       const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
      
        if (selectedFiles) {
          var filesArray=[]
          for(let i=0; i<selectedFiles.length; i++){
            const fileSizeInBytes = selectedFiles[i].size;
            const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024);
            if (fileSizeInMegabytes <= 10) {
              filesArray.push(selectedFiles[i])
            }
             
          }
          if(filesArray.length!==selectedFiles.length){
            dangerAlert(`${t("lang")==='uz'?selectedFiles.length-filesArray.length:''} ${t("error_image_mb")}`)
          }
          
          setimages([...filesArray.slice(0, 10-images.length), ...images]);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }
      };
      const handleVideoChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
      
        if (selectedFiles) {
          var filesArray=[]
          for(let i=0; i<selectedFiles.length; i++){
            const fileSizeInBytes = selectedFiles[i].size;
            const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024);
            if (fileSizeInMegabytes <= 100) {
              filesArray.push(selectedFiles[i])
            }
             
          }
          if(filesArray.length!==selectedFiles.length){
            dangerAlert(t("error_video_mb"))
          }
          setvideo(filesArray)
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }
      };
      const deleteImage=(key:number)=>{
        var a=images
        a.splice(key,1)
setimages(a)
setnumber(number+1)
      }
      const handleregion=async(id_new:number)=>{
        setdistrict(0)
        setdistrict_data([])
        var res=await httpGetRequest('/districts/?region='+id_new)
         setdistrict_data(res.data)
    
      }
      const handlectype=async(id_new:number)=>{
        setloader(true)
        setmodel_data([])
        setmodel(0)
        setposition_data([])
        setposition(0)
        try{
          var res=await httpGetRequest('/cars/models/', {ctype:id_new})
          setmodel_data(res.data)
          setloader(false)
        }catch(err){
          setloader(false)
        }
      }
      const handlecmtype=async(id_new:number)=>{
        setloader(true)
        setctype_data([])
        setctype(0)
        setmodel_data([])
        setmodel(0)
        setposition_data([])
        setposition(0)
        try{
          var res=await httpGetRequest('/cars/ctypes/', {cmtype:id_new})
          setctype_data(res.data)
          setloader(false)
        }catch(err){
          setloader(false)
        }
      }
      const handlemodel=async(id_new:number)=>{
        setloader(true)
        setposition_data([])
        setposition(0)
        try{
          var res=await httpGetRequest('/cars/positions/', {cmodel:id_new})
          setposition_data(res.data)
          setloader(false)
        }catch(err){
          setloader(false)
        }
      }
  return (
    <div  onClick={(e)=>{handleCloseSelect(e)}} className='announcment_client'>
        <div className={`announcment_filter client_open_filter`}>
        <h1 className='create_title'>{t("add_elon")}</h1>
           
            <div className='announcment_filter_body'>
            <div className='alert_text'> <FaTriangleExclamation className='alert_icon' />{t("alert_add_elon")}</div>
        <Row>
        <Col  className='filter_col' lg={8} md={24} sm={24}>
                <SimpleInputText required={required} max={70} placeholder={""} label={t("name_add")} value={name} setvalue={setname}/>
                </Col>
                <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect required={required} onchange={handleregion} name="region" label={t("region")} value={region} setvalue={setregion} data={region_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
            <SimpleSelect required={required} onchange={handlechange} name="district" label={t("district")} value={district} setvalue={setdistrict} data={district_data}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput required={required} placeholder={" "} label={t("price_uzs")} value={price_uzs} setvalue={setprice_uzs}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput required={required} placeholder={" "} label={t("price_usd")} value={price_usd} setvalue={setprice_usd}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  onchange={handlechange} name="b_type" label={t("b_type")} value={b_type} setvalue={setb_type} data={b_type_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect required={required}  onchange={handlecmtype} name="cmtype" label={t("car_type")} value={cmtype} setvalue={setcmtype} data={cmtype_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect required={required}  onchange={handlectype} name="ctype" label={t("marka")} value={ctype} setvalue={setctype} data={ctype_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect required={required}  onchange={handlemodel} name="model" label={t("model")} value={model} setvalue={setmodel} data={model_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  onchange={handlechange} name="position" label= {t("position")} value={position} setvalue={setposition} data={position_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  onchange={handlechange} name="kuzov" label= {t("kuzov")} value={kuzov} setvalue={setkuzov} data={kuzov_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  onchange={handlechange} name="transmission" label=  {t("transmission")} value={transmission} setvalue={settransmission} data={transmission_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  onchange={handlechange} name="drive_unit" label= {t("drive_unit")} value={drive_unit} setvalue={setdrive_unit} data={drive_unit_data}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput placeholder={" "} label={t("probeg")} value={probeg} setvalue={setprobeg}/>
            </Col>
           <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  onchange={handlechange} name="color" label= {t("car_color")} value={color} setvalue={setcolor} data={color_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  onchange={handlechange} name="color_state" label= {t("color_state")} value={color_state} setvalue={setcolor_state} data={color_state_data}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput placeholder={" "} label= {t("capacity")} value={capacity} setvalue={setcapacity}/>
            </Col>
          
            
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  onchange={handlechange} name="engine_type" label= {t("engine_type")} value={engine_type} setvalue={setengine_type} data={engine_type_data}/>
            </Col>
           
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput placeholder={" "} label= {t("car_year")} value={year} setvalue={setyear}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
            <div className={`input`}>
        <label>{t("agreed")}</label>
        <div className='agreed_box'><input className='agreed' onClick={()=>{setagreed(!agreed)}} style={{width:'auto'}} type='checkbox' checked={agreed}/></div>
        
        </div>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
            <div className={`input`}>
        <label >{t("autosalon")}</label>
        <div className='agreed_box'><input className='agreed' onClick={()=>{setautosalon(!autosalon)}} style={{width:'auto'}} type='checkbox' checked={autosalon}/></div>
        
        </div>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
            <div className={`input`}>
        <label >{t("car_rent")}</label>
        <div className='agreed_box'><input className='agreed' onClick={()=>{setrent(!rent)}} style={{width:'auto'}} type='checkbox' checked={rent}/></div>
        
        </div>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
            <div className={`input`}>
        <label >{t("long_term_pay")}</label>
        <div className='agreed_box'><input className='agreed' onClick={()=>{setlong_term_pay(!long_term_pay)}} style={{width:'auto'}} type='checkbox' checked={long_term_pay}/></div>
        
        </div>
            </Col>
            <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputPhone required={required} placeholder={""} label={t("phone")} value={phone} setvalue={setphone}/>
                </Col>
                <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label={t("Telegram")} value={telegram} setvalue={settelegram}/>
                </Col>
                <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label={t("Instagram")} value={instagram} setvalue={setinstagram}/>
                </Col>
                <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label={t("FaceBook")} value={facebook} setvalue={setfacebook}/>
                </Col>
                <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label={t("Vebsite")} value={website} setvalue={setwebsite}/>
                </Col>
                <Col lg={16} md={12} sm={24}></Col>
           <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}>
            <label>{t("additional")}</label>
           <div className='quill_box'> <ReactQuill
        theme="snow" // You can choose different themes like 'bubble' or 'snow'
        value={additional}
        onChange={handleEditorChange}
      /></div>
            </Col>
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}>
            <label>{t("select_video")}</label>
            <input ref={videoInputRef} onChange={handleVideoChange} className='file_input' accept="video/*" type='file' />
            <p className='file_text'>{t("alert_video")}</p>
            </Col>
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}>
            <br/>
            <br/>
            <label>{t("select_image")}</label>
            <input ref={fileInputRef} onChange={handleFileChange} className='file_input' accept="image/*" type='file' multiple />
            <p className='file_text'>{t("alert_image")}</p>
            </Col>
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}></Col>
            
            {images.map((item, key)=>{
                return(<Col key={key} className='filter_col' lg={6} md={12} sm={24}>
                    <div className='image_box'>
                       <img src={URL.createObjectURL(item)}/>
                       <div onClick={()=>{deleteImage(key)}} className='img_delete_btn'><IoCloseCircleSharp /></div>
                       <input className='active_image' onChange={()=>setimage(key)} type="radio" name="image" value={key} checked={key===image} />
                    </div>
                    
                </Col>)
            })}
        </Row>
        <div className='filter_btns'>
         
          <button onClick={onFinish} className='save_btn'>{t("save")}</button>
        </div>
        </div>
        </div>
       
    </div>
  )
}
