import React from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../images/logo.png'
import { Link } from 'react-router-dom'
import { FaTelegramPlane } from 'react-icons/fa'
import { FaFacebook, FaFacebookF, FaInstagram, FaTelegram, FaYoutube } from 'react-icons/fa6'
export default function Footer() {
  const {t}=useTranslation()
  return (
    <div className='footer'>
      <div className='footer_top'>
        <div className='footer_img'>
          <img src={logo}/>
        </div>
        <div className='footer_menu'>
        <Link to="/selectors?big_category=1&category=1" className='more_item'>
                <p>{t("turar_joy")}</p>
                </Link>
               
                <Link to="/selectors?big_category=1&category=3" className='more_item'>
                <p>{t("material")}</p>
                </Link>
                
                <Link to="/selectors?big_category=2&category=5" className='more_item'>
                <p>{t("cars")}</p>
                </Link>
              
                <Link to="/selectors?big_category=2&category=7" className='more_item'>
                <p>{t("material_car")}</p>
                </Link>
               
        </div>
        <div className='footer_links'>
          <a href="https://www.facebook.com/uzelon" target='_blank'><FaFacebook /></a>
          <a href="https://www.instagram.com/uyavto.uz/" target='_blank'><FaInstagram /></a>
          <a href="https://t.me/Uy_avto_Uz" target='_blank'><FaTelegram /></a>
          <a href="https://www.youtube.com/@uyavtouz" target='_blank'><FaYoutube /></a>
        </div>
        </div>
        <div className='footer_bottom'>
          <div className='footer_bottom_content'>
          <a>{t("footer_a")}</a>
          <a href="#" target='_blank'>{t("privacy")}</a>
          <a href="#" target='_blank'>{t("term_of_service")}</a>
          <a href="#" target='_blank'>{t("cookie_setting")}</a>
        </div>
        </div>
    </div>
  )
}
