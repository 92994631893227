import { Col } from 'antd'
import React from 'react'
import { FaClock, FaLocationDot, FaRegBookmark } from 'react-icons/fa6'
import home from '../../../images/logo.png';
import {Variants, motion} from 'framer-motion'
import { Link, useLocation } from 'react-router-dom';
import ImageBox from '../../ImageBox';
import { useTranslation } from 'react-i18next';
import { ProductObject, useStore } from '../../../store/Store';
import { filterPrice, formatDate } from '../../Alert';

  interface MultipleSelectProps {
   data:ProductObject;number_key:number;
  }
  const CardCarProduct: React.FC<MultipleSelectProps>=({data, number_key})=>{
  const {t}=useTranslation()
  const {money_type, setold_link}=useStore()
  let location = useLocation();
  return (
    <Col lg={24} md={24} sm={24}>
        <div>
        <div>
        <Link onClick={()=>{setold_link(location.search)}} to={"/product/car/product/"+data.id} className='card'>
            {/* <div className="lenta_card">
                <h1>TOP</h1>
            </div> */}
            <div className='card_img'>
            <ImageBox alt='image'  src={data.image!==null?data.image.image:home} />
            </div>
            <div className='card_text'>
                <div className='card_text_head'>
                <h1>{data.name!=null?data.name:''}</h1>
                
                </div>
                <div className='card_address'>
                    <div className='card_item'>
                        <span><FaLocationDot /></span>
                        <p>{data.region!=null?data.region[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}, {data.district!=null?data.district[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}</p>
                        </div>
                        
                    <div className='card_item card_time'>
                        <span><FaClock /></span>
                        <p>{formatDate(data.created_at)}</p>
                        </div>
                   
                </div>
                <div className='card_price'>
                    <p>{money_type===2?filterPrice(data.price_usd)+" $":filterPrice(data.price_uzs)+t(t(" so'm"))}</p>
                    
                </div>
                <div className='card_icon'><FaRegBookmark /></div>
            </div>
        </Link>
        </div>
        </div>
    </Col>
  )
}
export default CardCarProduct