import { PuffLoader } from "react-spinners"


const Loader=()=>{
  return(
    <div className="loader">
        
<PuffLoader color="#e19e5e" />
    </div>
  )
}
export default Loader