import { Button, Col, Modal, Row, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import SimpleSelect from '../../../components/SimpleSelect'
import MultipleSelect from '../../../components/MultipleSelect'
import { bolData, closeSelect } from '../../../components/Data'
import SimpleInput from '../../../components/SimpleInput'
import SearchBox from '../../../components/SearchBox'
import CardClientMini from '../../../components/cards/CardClientMini'
import { IoAddCircle } from 'react-icons/io5'
import { BiSolidBellRing } from "react-icons/bi";
import { Link } from 'react-router-dom'
import { HomeObject, useStore } from '../../../store/Store'
import { httpDeleteRequest, httpGetRequest, httpPatchRequest, httpPostRequest } from '../../../host/Host'
import { dangerAlert, successAlert } from '../../../components/Alert'
import { ClipLoader } from 'react-spinners'
import { useTranslation } from 'react-i18next'
export default function HomeAnnoucment() {
    const {house_type_data, b_type_data, repair_data, near_data}=useStore()
    const [b_type__in, setb_type__in]=useState<string | null>(null)
    const [furnished, setfurnished]=useState<string | null>(null)
    const [near__in, setnear__in]=useState<string | null>(null)
    const [status, setstatus]=useState<string | null>(null)
    const [repair__in, setrepair__in]=useState<string | null>(null)
    const [makler, setmakler]=useState<string | null>(null)
    const [number, setnumber]=useState(1)
    const [selected, setselected]=useState<number[]>([])
    const [price_uzs__gte, setprice_uzs__gte]=useState<number|string>('')
    const [price_uzs__lte, setprice_uzs__lte]=useState<number|string>('')
    const [rooms__gte, setrooms__gte]=useState<number|string>('')
    const [rooms__lte, setrooms__lte]=useState<number|string>('')
    const [floor__gte, setfloor__gte]=useState<number|string>('')
    const [floor__lte, setfloor__lte]=useState<number|string>('')
    const [surface__gte, setsurface__gte]=useState<number|string>('')
    const [surface__lte, setsurface__lte]=useState<number|string>('')
    const [year__gte, setyear__gte]=useState<number|string>('')
    const [year__lte, setyear__lte]=useState<number|string>('')
    const [name__icontains, setname__icontains]=useState<string>('')
    const [open_filter, setopen_filter]=useState(true)
    const [page, setpage]=useState(1)
    const [next, setnext]=useState(1)
    const [axios_send, setaxios_send]=useState(0)
    const [mini_loader, setmini_loader]=useState(true)
    const [data, setdata]=useState<HomeObject[]>([])
    const [house_type__in, sethouse_type__in]=useState<string | null>(null)
    const {setloader}=useStore()
    const {t} =useTranslation()
    const restoreFilter=()=>{
             setdata([])
             setb_type__in(null)
             setfurnished(null)
             setnear__in(null)
             setstatus(null)
             setrepair__in(null)
             setmakler(null)
             setselected([])
             setprice_uzs__gte('')
             setprice_uzs__lte('')
             setrooms__gte('')
             setrooms__lte('')
             setfloor__gte('')
             setfloor__lte('')
             setsurface__gte('')
             setsurface__lte('')
             setyear__gte('')
             setyear__lte('')
             setname__icontains('')
             sethouse_type__in(null)
             setnumber(number+1)
             setmini_loader(true)
             getData(1,1,{page:1})
             
    }
    const elementRef = useRef<HTMLDivElement>(null);
    useEffect(()=>{
      getData(next, page)
    }, [])

    const getData=async(next_data:number|null, page_data:number, dat?:{page:number})=>{
        if(next_data!==null){
            setmini_loader(true)
            setaxios_send(1)
           
            
            if(dat!==undefined){
                config={
                    price_uzs__gte:null,
                    price_uzs__lte:null,
                    floor__gte:null,
                    floor__lte:null,
                    rooms__gte:null,
                    rooms__lte:null,
                    year__gte:null,
                    year__lte:null,
                    surface__gte:null,
                    surface__lte:null,
                    house_type__in:null,
                    b_type__in:null,
                    repair__in:null,
                    furnished:null,
                    near__in:null,
                    makler:null,
                    status:null,
                    page:page_data,
                    name__icontains:null
                }
            }else{
                var config={
                    price_uzs__gte:String(price_uzs__gte).length===0?null:String(price_uzs__gte),
                    price_uzs__lte:String(price_uzs__lte).length===0?null:String(price_uzs__lte),
                    floor__gte:String(floor__gte).length===0?null:String(floor__gte),
                    floor__lte:String(floor__lte).length===0?null:String(floor__lte),
                    rooms__gte:String(rooms__gte).length===0?null:String(rooms__gte),
                    rooms__lte:String(rooms__lte).length===0?null:String(rooms__lte),
                    year__gte:String(year__gte).length===0?null:String(year__gte),
                    year__lte:String(year__lte).length===0?null:String(year__lte),
                    surface__gte:String(surface__gte).length===0?null:String(surface__gte),
                    surface__lte:String(surface__lte).length===0?null:String(surface__lte),
                    house_type__in:house_type__in!=null?house_type__in.replaceAll("[", '').replaceAll("]", '').length!==0?house_type__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                    b_type__in:b_type__in!=null?b_type__in.replaceAll("[", '').replaceAll("]", '').length!==0?b_type__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                    repair__in:repair__in!=null?repair__in.replaceAll("[", '').replaceAll("]", '').length!==0?repair__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                    near__in:near__in!=null?near__in.replaceAll("[", '').replaceAll("]", '').length!==0?near__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                    makler:makler!=null && JSON.parse(makler).length!==2?makler.replaceAll("[", '').replaceAll("]", '').length!==0?bolData[Number(makler.replaceAll("[", '').replaceAll("]", ''))]:null:null,
                    furnished:furnished!=null && JSON.parse(furnished).length!==2?furnished.replaceAll("[", '').replaceAll("]", '').length!==0?bolData[Number(furnished.replaceAll("[", '').replaceAll("]", ''))]:null:null,
                    status:status!=null && JSON.parse(status).length!==2?status.replaceAll("[", '').replaceAll("]", '').length!==0?status.replaceAll("[", '').replaceAll("]", ''):null:null,
                    page:page_data,
                    name__icontains:name__icontains.length===0?null:name__icontains
                }
            }
            
            
            try{
               var res=await httpGetRequest('/houses/my/', config)
               if(page_data===1){
                var a=[...res.data.results]
               }else{
                var a=[...data, ...res.data.results]
               }
               setdata(a)
               setnext(res.data.next)
               setpage(page_data+1)
               
               setaxios_send(0)
               setloader(false)
               setmini_loader(false)
               setnumber(number+1)
            }catch(err){
              console.log(err)
              setaxios_send(0)
              setmini_loader(false)
            }
        }
        
    }
     
    
    const handleCloseSelect=(e: React.MouseEvent<HTMLDivElement>)=>{
     
        if (e.target instanceof HTMLElement && e.target.className.indexOf("select")===-1 && e.target.parentElement instanceof HTMLElement && e.target.parentElement.className.indexOf("select")===-1) {
          // Handle the body click here
          closeSelect("a");
        }
       }
       const deleteHomeAnn=async(id:number)=>{
        setloader(true)
       try {
           var res=await httpDeleteRequest(`/houses/${id}/delete/`)
           successAlert(t("info_deleted"))
           getData(1, 1)
       }catch(err){
           dangerAlert(t("info_not_deleted"))
           setloader(false)
           getData(1, 1)
       }
    }
    const unActiveHomeAnn=async(id:number)=>{
        setloader(true)
       try {
           var res=await httpPatchRequest(`/houses/${id}/change-status/`, {status:0})
           var k=null
           data.forEach((x, y)=>{
            if(x.id===id){
                k=y
            }
           })
           var g=data
           if(k!==null){
            g[k].status=0
           }
           setdata(g)
           setloader(false)
           setnumber(number+1)
       }catch(err){
          
           setloader(false)
           
       }
    }
    const deleteHome=(id:number)=>{
        Modal.confirm({
            title: t("alert"),
            content: t("alert_delete"),
            onOk() {
               deleteHomeAnn(id); // Call the function to delete the data
            },
              cancelText: t("cancel"), // Text for the Cancel button
              okText: t("delete"),
          });
    }
    const unActiveHome=(id:number)=>{
        Modal.confirm({
            title: t("alert"),
            content: t("alert_active"),
            onOk() {
               unActiveHomeAnn(id); // Call the function to delete the data
            },
              cancelText: t("cancel"), // Text for the Cancel button
              okText: t("stop_active"),
          });
    }
    const handleScroll = () => {
        
        if(document.querySelector('.axios_send') && document.querySelector('.axios_send')?.innerHTML==='0'){
            if (!elementRef.current) return;
    
            const { scrollTop, clientHeight, scrollHeight } = elementRef.current;
            if (scrollTop + clientHeight >= scrollHeight-200) {
             getData(next, page)
            }
        }
        
      };
      const activeHomes=async()=>{
       if(selected.length!==0){
            setloader(true)
               try{
                console.log(selected)
                 var res=await httpPostRequest(`/houses/change-status/`, {status:1, ads:selected})
                 var f=data.map((item, key)=>{
                    if(selected.indexOf(item.id)!==-1){
                        item.status=1
                    } 
                    return(item)
                 })
                 setdata(f)
                 setselected([])
                 setnumber(number+1)
                 setloader(false)
               }catch(err){
                setloader(false)
               }
        }
      }
      const searchFilter=()=>{
        setdata([])
        getData(1,1)
      }
  return (
    <div ref={elementRef} onScroll={handleScroll}
     onClick={(e)=>{handleCloseSelect(e)}} className='announcment_client'>
        <p className='axios_send' style={{display:"none"}}>{axios_send}</p>
        <div className='phone_client_title'>{t("houses")}</div>
        <div className={`announcment_filter ${open_filter?'client_open_filter':''}`}>
            <div className={`announcment_filter_head`} >
            <h1 onClick={()=>setopen_filter(!open_filter)}>{t("filters")}</h1>
            <div className='client_search'>
               <SearchBox getData={searchFilter} search={name__icontains} setsearch={setname__icontains}/>
            </div>
            </div>
           
            <div className='announcment_filter_body phone_filter'>
        <Row>
        <Col className='filter_col' lg={8} md={12} sm={24}>
        <MultipleSelect name="house_type__in" label={t("house_type")} value={house_type__in} setvalue={sethouse_type__in} data={house_type_data}/>
            </Col>
        <Col className='filter_col' lg={8} md={12} sm={24}>
        <MultipleSelect name="bozor" label={t("b_type")} value={b_type__in} setvalue={setb_type__in} data={b_type_data}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={t("price_uzs")} value={price_uzs__gte} setvalue={setprice_uzs__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={price_uzs__lte} setvalue={setprice_uzs__lte}/>
            </Col>
              <Col className='filter_col filter_col_right' lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={t("rooms")} value={rooms__gte} setvalue={setrooms__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={rooms__lte} setvalue={setrooms__lte}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={t("floor")} value={floor__gte} setvalue={setfloor__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={floor__lte} setvalue={setfloor__lte}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={t("surface")} value={surface__gte} setvalue={setsurface__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={surface__lte} setvalue={setsurface__lte}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={t("build_year")} value={year__gte} setvalue={setyear__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={year__lte} setvalue={setyear__lte}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <MultipleSelect name="furnished" label={t("furnished")} value={furnished} setvalue={setfurnished} data={[
                    {id:1, name_ru:"Есть", name_uz:"Bor"},
                    {id:2, name_ru:"Нет", name_uz:"Yo'q"},
                ]}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <MultipleSelect name="makler" label={t("makler")} value={makler} setvalue={setmakler} data={[
                    {id:1, name_ru:"Есть", name_uz:"Bor"},
                    {id:2, name_ru:"Нет", name_uz:"Yo'q"},
                ]}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <MultipleSelect name="repair__in" label={t("repair")} value={repair__in} setvalue={setrepair__in} data={repair_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <MultipleSelect name="near__in" label={t("near")} value={near__in} setvalue={setnear__in} data={near_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <MultipleSelect name="status" label={t("status")} value={status} setvalue={setstatus} data={[
                    {id:1, name_ru:"Актив", name_uz:"Aktiv"},
                    {id:0, name_ru:"Не актив", name_uz:"Aktiv emas"},
                ]}/>
            </Col>
        </Row>
        <div className='filter_btns'>
          <button onClick={restoreFilter} className='restore_btn'>{t("restore")}</button>
          <button className='save_btn' onClick={searchFilter}>{t("search")}</button>
        </div>
        </div>
        </div>
        <div className='announcment_btns'>
                <div className='active_btns_box'>
                <div  className='active_btns_item'>
                    <div className='client_card_text active_text'/>
                    <p> - {t("active_adds")}</p>
                    </div>
                    <div  className='active_btns_item'>
                    <div className='client_card_text denger_text'/>
                    <p> - {t("no_active_adds")}</p>
                    </div>
                
                </div>
                <div className='filter_btns active_btns_btn'>
                    {selected.length===0?<Tooltip placement="bottom" title={t("choose_add_for_activate")}>
                <button className='restore_btn'> {t("activete")} <span><BiSolidBellRing /></span></button>
                </Tooltip>:
                <>
                <p className='selected_text_num'>{selected.length} {t("chosed_adds_number")}</p>
                <Tooltip placement="bottom" title={t("restore_choosed")}>
                <button onClick={()=>setselected([])} className='restore_btn'>{t("restore_choos")} <span><BiSolidBellRing /></span></button>
                </Tooltip>
                <Tooltip placement="bottom" title={t("tool_active")}>
                <button  onClick={activeHomes} className='success_btn'>{t("activete")} <span><BiSolidBellRing /></span></button>
                </Tooltip>
                </>}
                
                <Link to={'create'}>
                   <button className='save_btn'> {t("add_elon")} <span><IoAddCircle /></span></button>
                   </Link>
                 </div>
            </div>
       <div className='client_card_box'>
        <Row>
            {data.map((item, key)=>{
                return( <CardClientMini key={key} data={item} deleteHome={deleteHome} unActiveHome={unActiveHome} setselected={setselected}  selected={selected} setnumber={setnumber} number={number}/>)
            })}
           
        </Row>
        {mini_loader?
        <div className='mini_loader_box'><ClipLoader size={50} color="#e19e5e" /></div>
:<></>}
       </div>
    </div>
  )
}
