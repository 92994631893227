import { TypeObject } from "../store/Store";
import carous_car_1 from '../images/carous_car/carous_car_1.jpg'
import carous_car_2 from '../images/carous_car/carous_car_2.jpg'
import carous_car_3 from '../images/carous_car/carous_car_3.jpg'
import carous_car_4 from '../images/carous_car/carous_car_4.jpg'
import carous_1 from '../images/carous_home/carous_1.jpg'
import carous_2 from '../images/carous_home/carous_2.jpg'
import carous_3 from '../images/carous_home/carous_3.jpg'
import carous_4 from '../images/carous_home/carous_4.jpg'
  
  type Categories = {
    [key: string]: TypeObject[];
  };
  type Label = {
    [key: string]: string;
  };
export type ImageType={
    id:number, image:string
}

export const API_KEY="244a58c4-a20f-46fb-b5bf-97c3865d37c9"

export const closeSelect=(str:string)=>{
    var b=document.querySelector(`.select_active`)
        
        if(b){
          b.classList.remove('select_active');
            return(!b.classList.contains(str))
         }else{
            return(true)
         }
}
export const bolData=[null, true, false]
export const categories: Categories={
    '1':[
        {id:1, name_ru:"Размещение", name_uz:"Turar joy"},
        {id:2, name_ru:"Мастер сервис", name_uz:"Usta xizmati"},
        {id:3, name_ru:"Товары для дома", name_uz:"Uy-ro'zg'or buyumlari"},
        {id:4, name_ru:"Ипотека", name_uz:"Ipoteka"},
    ],
    '2':[
        {id:5, name_ru:"Автотранспорт", name_uz:"Avtotransport"},
        {id:6, name_ru:"Мастер сервис", name_uz:"Usta xizmati"},
        {id:7, name_ru:"Запчасти", name_uz:"Ehtiyot qismlar"},
        {id:8, name_ru:"Авто кредит", name_uz:"Avtokredit"},
    ]
}







export const gender_data=[
    {id:1, name_ru:"Мужчина", name_uz:"Erkak"},
        {id:2, name_ru:"Женщина", name_uz:"Ayol"},
]
export const gender_data_all=[
    {id:0, name_ru:"Все", name_uz:"Barchasi"},
    {id:1, name_ru:"Мужчина", name_uz:"Erkak"},
        {id:2, name_ru:"Женщина", name_uz:"Ayol"},
]
export const sort_data=[
    null,
    'price_uzs',
    '-price_uzs',
    'created_at',
    '-created_at',
]
export const products: Categories={
    '1':[
        {id:1, name_ru:"Kop qavatli uylar", name_uz:"Kop qavatli uylar"},
        {id:2, name_ru:"Yangi uylar", name_uz:"Yangi uylar"},
        {id:3, name_ru:"No Turar joylar", name_uz:"No Turar joylar"},
        {id:4, name_ru:"Qurilishi tugalanmagan binolar", name_uz:"Qurilishi tugalanmagan binolar"},
        {id:5, name_ru:"Kotejlar ", name_uz:"Kotejlar "},
        {id:6, name_ru:"Quruq yer", name_uz:"Quruq yer"},
        {id:7, name_ru:"Ipoteka uylar", name_uz:"Ipoteka uylar"},
        {id:8, name_ru:"Arenda uy", name_uz:"Arenda uy"},
        {id:9, name_ru:"Arenda noTurar joylar", name_uz:"Arenda noTurar joylar"},
    ],
    '2':[
        {id:10, name_ru:"Dezayner va prarab xizmati", name_uz:"Dezayner va prarab xizmati"},
        {id:11, name_ru:"Ustalar", name_uz:"Ustalar"},
        {id:12, name_ru:"Qurulish mollari", name_uz:"Qurulish mollari"},
        {id:13, name_ru:"Mebelchilar", name_uz:"Mebelchilar"},
        {id:14, name_ru:"Santexnika xizmati", name_uz:"Santexnika xizmati"},
        {id:15, name_ru:"Elektirik xizmati", name_uz:"Elektirik xizmati"},
        {id:16, name_ru:"Sivarchi xizmati", name_uz:"Sivarchi xizmati"},
        {id:17, name_ru:"Eshik romlar", name_uz:"Eshik romlar"},
        {id:18, name_ru:"Landshaf va bogdorchilik", name_uz:"Landshaf va bogdorchilik"},
        {id:19, name_ru:"Gul va kochatlar", name_uz:"Gul va kochatlar"},
        {id:20, name_ru:"Parda va qilamlar", name_uz:"Parda va qilamlar"},
        {id:21, name_ru:"Kameea qoyish", name_uz:"Kameea qoyish"},
        {id:22, name_ru:"Bitovoy texnika va idish tovoq", name_uz:"Bitovoy texnika va idish tovoq"},
        {id:23, name_ru:"Tadbirlada bezaklar", name_uz:"Tadbirlada bezaklar"},
    ],
    '4':[
        {id:1, name_ru:"Differensial", name_uz:"Differensial"},
        {id:2, name_ru:"Anuintet", name_uz:"Anuintet"},
    ]
}

export const labelProduct: Label={
    '1':"Turar joy turi",
    '2':"Xizmat turi",
    '3':"Homashyo turi",
    '4':"Kredit turi",
    '5':"Avtotransport turi",
    '6':"Xizmat turi",
    '7':"Ehtiyot qismlar turi",
    '8':"Kredit turi",
}


export const carous_home_data=[
    {
        id:1,
        image:carous_1,
        car_title:{
            "uz":"Ishonchlilik",
            "ru":'Надёжность'
        },
        title:{
            "uz":"Xavfsiz ijara kafolati",
            "ru":'Гарантия безопасной аренды'
        },
        text:{
            "uz":"Hukumat tashkilotlari bilan rasmiy shartnoma",
            "ru":'Официальный договор с государственной компанией'
        }
    },
    {
        id:2,
        image:carous_2,
        car_title:{
            "uz":"Qulaylik",
            "ru":'Удобство'
        },
        title:{
            "uz":"Lokatsiyadagi qulayliklar",
            "ru":'Удобства на локации'
        },
        text:{
            "uz":"Bizning joylashuvimizda sizning qulayligingiz uchun barcha sharoitlar yaratilgan",
            "ru":'На нашей локации созданы все условия для вашего комфорта'
        }
    },
    {
        id:3,
        image:carous_3,
        car_title:{
            "uz":"Turli-tumanlik",
            "ru":'Вариативность'
        },
        title:{
            "uz":"Keng tanlov, har qanday didga mos",
            "ru":'Широкий выбор на любой вкус'
        },
        text:{
            "uz":"Biz qulay yashash uchun keng tanlovni taklif etamiz",
            "ru":'Мы предлагаем широкий выбор вариантов для комфортного проживания'
        }
    },
    {
        id:4,
        image:carous_4,
        car_title:{
            "uz":"Komfort",
            "ru":'Комфорт'
        },
        title:{
            "uz":"Har bir burchakda qulaylik va issiqlik",
            "ru":'Уют и тепло в каждом уголке'
        },
        text:{
            "uz":"O'zingizni o'z uyingizdagidek his qilishingiz uchun har bir detalga e'tibor beramiz",
            "ru":'Мы заботимся о каждой детали, чтобы вы чувствовали себя как дома'
        }
    }
]

export const carous_car_data=[
    {
        id:1,
        image:carous_car_1,
        car_title:{
            "uz":"Sifatlilik ",
            "ru":'Качество'
        },
        title:{
            "uz":"Har bir detallarda mukammallik",
            "ru":'Превосходство в каждой детали'
        },
        text:{
            "uz":"Yuqori sifat — bu nafaqat tashqi ko'rinish, balki texnik xususiyatlar, qulaylik va xavfsizlikdir",
            "ru":'Высокое качество — это не только внешний вид, но и технические характеристики, комфорт и безопасность'
        }
    },
    {
        id:2,
        image:carous_car_2,
        car_title:{
            "uz":"Arzon narxlar",
            "ru":'Низкие цены'
        },
        title:{
            "uz":"Arzon narxdagi sifat",
            "ru":'Качество по доступной цене'
        },
        text:{
            "uz":"Biz jozibali narxlarda avtomobillarni taklif etamiz, shu bilan birga sifat va xizmat ko'rsatishning yuqori standartlarini saqlaymiz",
            "ru":'Мы предлагаем автомобили по привлекательным ценам, сохраняя при этом высокие стандарты качества и обслуживания'
        }
    },
    {
        id:3,
        image:carous_car_3,
        car_title:{
            "uz":"Mukammal tanlov",
            "ru":'Идеальный выбор'
        },
        title:{
            "uz":"Siz kutgan barcha avtomobillar",
            "ru":'Автомобили, которые соответствуют вашим ожиданиям'
        },
        text:{
            "uz":"Biz har qanday ehtiyojlaringiz uchun mukammal mos keladigan eng yaxshi avtomobillarni taklif etamiz",
            "ru":'Мы предлагаем только лучшие автомобили, которые идеально подойдут для любых нужд'
        }
    },
    {
        id:4,
        image:carous_car_4,
        car_title:{
            "uz":"Yuqori xizmat",
            "ru":'Высокий сервис'
        },
        title:{
            "uz":"Kasb mahorati va har qadamda g'amxo'rlik",
            "ru":'Профессионализм и забота на каждом шаге'
        },
        text:{
            "uz":"Biz yuqori darajadagi xizmat ko'rsatishni kafolatlaymiz, bu esa har bir mijozga e'tibor qaratishni o'z ichiga oladi",
            "ru":'Мы гарантируем высокий уровень обслуживания, который включает в себя внимание к каждому клиенту'
        }
    }
]




















