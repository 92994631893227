import { Col, Modal, Row, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import MultipleSelect from '../../../components/MultipleSelect'
import { closeSelect } from '../../../components/Data'
import SimpleInput from '../../../components/SimpleInput'
import SearchBox from '../../../components/SearchBox'
import { IoAddCircle } from 'react-icons/io5'
import { BiSolidBellRing } from "react-icons/bi";
import { Link } from 'react-router-dom'
import { CreditObject, useStore } from '../../../store/Store'
import { httpDeleteRequest, httpGetRequest, httpPatchRequest, httpPostRequest } from '../../../host/Host'
import { dangerAlert, successAlert } from '../../../components/Alert'
import { ClipLoader } from 'react-spinners'
import CardClientCarCreditMini from '../../../components/cards/CardClientCarCreditMini'
import { useTranslation } from 'react-i18next'
export default function CarCreditAnnoucment() {
    const {credit_type_data, b_type_data}=useStore()
    
    const [status, setstatus]=useState<string | null>(null)
   
    const [number, setnumber]=useState(1)
    const [selected, setselected]=useState<number[]>([])
    const [summ__gte, setsumm__gte]=useState<number|string>('')
    const [summ__lte, setsumm__lte]=useState<number|string>('')
    const [percent__gte, setpercent__gte]=useState<number|string>('')
    const [percent__lte, setpercent__lte]=useState<number|string>('')
   
    const [name__icontains, setname__icontains]=useState<string>('')
    const [open_filter, setopen_filter]=useState(true)
    const [page, setpage]=useState(1)
    const [next, setnext]=useState(1)
    const [axios_send, setaxios_send]=useState(0)
    const [mini_loader, setmini_loader]=useState(true)
    const [data, setdata]=useState<CreditObject[]>([])
    const [itype__in, setitype__in]=useState<string | null>(null)
    const [b_type__in, setb_type__in]=useState<string | null>(null)
    const {setloader}=useStore()
   const {t}=useTranslation()
    const restoreFilter=()=>{
             setdata([])
            
             setstatus(null)
            
             setselected([])
             
             setsumm__gte('')
             setsumm__lte('')
             setpercent__gte('')
             setpercent__lte('')
           
             setname__icontains('')
             setitype__in(null)
             setb_type__in(null)
             setnumber(number+1)
             setmini_loader(true)
             getData(1,1,{page:1})
             
    }
    const elementRef = useRef<HTMLDivElement>(null);
    useEffect(()=>{
      getData(next, page)
    }, [])

    const getData=async(next_data:number|null, page_data:number, dat?:{page:number})=>{
        if(next_data!==null){
            setmini_loader(true)
            setaxios_send(1)
           
            
            if(dat!==undefined){
                config={
                    summ__gte:null,
                    summ__lte:null,
                    percent__gte:null,
                    percent__lte:null,
                    itype__in:null,
                    b_type__in:null,
                    status:null,
                    page:page_data,
                    name__icontains:null
                }
            }else{
                var config={
                    summ__gte:String(summ__gte).length===0?null:String(summ__gte),
                    summ__lte:String(summ__lte).length===0?null:String(summ__lte),
                    percent__gte:String(percent__gte).length===0?null:String(percent__gte),
                    percent__lte:String(percent__lte).length===0?null:String(percent__lte),
                    b_type__in:b_type__in!=null?b_type__in.replaceAll("[", '').replaceAll("]", '').length!==0?b_type__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                    itype__in:itype__in!=null?itype__in.replaceAll("[", '').replaceAll("]", '').length!==0?itype__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                    status:status!=null && JSON.parse(status).length!==2?status.replaceAll("[", '').replaceAll("]", '').length!==0?status.replaceAll("[", '').replaceAll("]", ''):null:null,
                    page:page_data,
                    name__icontains:name__icontains.length===0?null:name__icontains
                }
            }
            
            
            try{
               var res=await httpGetRequest('/auto-credits/my/', config)
               if(page_data===1){
                var a=[...res.data.results]
               }else{
                var a=[...data, ...res.data.results]
               }
               setdata(a)
               setnext(res.data.next)
               setpage(page_data+1)
               setaxios_send(0)
               setloader(false)
               setmini_loader(false)
               setnumber(number+1)
            }catch(err){
              console.log(err)
              setaxios_send(0)
              setmini_loader(false)
            }
        }
        
    }
     
    
    const handleCloseSelect=(e: React.MouseEvent<HTMLDivElement>)=>{
     
        if (e.target instanceof HTMLElement && e.target.className.indexOf("select")===-1 && e.target.parentElement instanceof HTMLElement && e.target.parentElement.className.indexOf("select")===-1) {
          // Handle the body click here
          closeSelect("a");
        }
       }
       const deleteHomeAnn=async(id:number)=>{
        setloader(true)
       try {
           var res=await httpDeleteRequest(`/auto-credits/${id}/delete/`)
           successAlert(t("info_deleted"))
           getData(1, 1)
       }catch(err){
           dangerAlert(t("info_not_deleted"))
           setloader(false)
           getData(1, 1)
       }
    }
    const unActiveHomeAnn=async(id:number)=>{
        setloader(true)
       try {
           var res=await httpPatchRequest(`/auto-credits/${id}/change-status/`, {status:0})
           var k=null
           data.forEach((x, y)=>{
            if(x.id===id){
                k=y
            }
           })
           var g=data
           if(k!==null){
            g[k].status=0
           }
           setdata(g)
           setloader(false)
           setnumber(number+1)
       }catch(err){
          
           setloader(false)
           
       }
    }
    const deleteHome=(id:number)=>{
        Modal.confirm({
            title: t("alert"),
            content:t("alert_delete"),
            onOk() {
               deleteHomeAnn(id); // Call the function to delete the data
            },
              cancelText:t("cencel"), // Text for the Cancel button
              okText: t("delete"),
          });
    }
    const unActiveHome=(id:number)=>{
        Modal.confirm({
            title: t("alert"),
            content:t("alert_active"),
            onOk() {
               unActiveHomeAnn(id); // Call the function to delete the data
            },
              cancelText:t("cencel"), // Text for the Cancel button
              okText:t("stop_active"),
          });
    }
    const handleScroll = () => {
        
        if(document.querySelector('.axios_send') && document.querySelector('.axios_send')?.innerHTML==='0'){
            if (!elementRef.current) return;
    
            const { scrollTop, clientHeight, scrollHeight } = elementRef.current;
            if (scrollTop + clientHeight >= scrollHeight-200) {
             getData(next, page)
            }
        }
        
      };
      const activeHomes=async()=>{
       if(selected.length!==0){
            setloader(true)
               try{
                 var res=await httpPostRequest(`/auto-credits/change-status/`, {status:1, ads:selected})
                 var f=data.map((item, key)=>{
                    if(selected.indexOf(item.id)!==-1){
                        item.status=1
                    } 
                    return(item)
                 })
                 setdata(f)
                 setselected([])
                 setnumber(number+1)
                 setloader(false)
               }catch(err){
                setloader(false)
               }
        }
      }
      const searchFilter=()=>{
        setdata([])
        getData(1,1)
      }
  return (
    <div ref={elementRef} onScroll={handleScroll}
     onClick={(e)=>{handleCloseSelect(e)}} className='announcment_client'>
        <p className='axios_send' style={{display:"none"}}>{axios_send}</p>
        <div className='phone_client_title'>{t("auto_credit")}</div>
        <div className={`announcment_filter ${open_filter?'client_open_filter':''}`}>
            <div className={`announcment_filter_head`} >
            <h1 onClick={()=>setopen_filter(!open_filter)}>{t("filters")}</h1>
            <div className='client_search'>
               <SearchBox getData={searchFilter} search={name__icontains} setsearch={setname__icontains}/>
            </div>
            </div>
           
            <div className='announcment_filter_body phone_filter' >
        <Row>
        <Col className='filter_col' lg={8} md={12} sm={24}>
        <MultipleSelect name="itype__in" label={t("credit_type")} value={itype__in} setvalue={setitype__in} data={credit_type_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
        <MultipleSelect name="b_type__in" label={t("b_type")} value={b_type__in} setvalue={setb_type__in} data={b_type_data}/>
            </Col>
       
            <Col className='filter_col filter_col_right' lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={t("credit_summ")} value={summ__gte} setvalue={setsumm__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={summ__lte} setvalue={setsumm__lte}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={4} md={6} sm={12}>
                <SimpleInput percent={true} placeholder={t("dan")} label={t("percent_credit")} value={percent__gte} setvalue={setpercent__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' lg={4} md={6} sm={12}>
                <SimpleInput  percent={true} placeholder={t("gacha")} label={" "} value={percent__lte} setvalue={setpercent__lte}/>
            </Col>
             
            
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <MultipleSelect name="status" label={t("status")} value={status} setvalue={setstatus} data={[
                    {id:1, name_ru:"Активный", name_uz:"Aktiv"},
                    {id:0, name_ru:"Не актив", name_uz:"Aktiv emas"},
                ]}/>
            </Col>
        </Row>
        <div className='filter_btns'>
          <button onClick={restoreFilter} className='restore_btn'>{t("restore")}</button>
          <button className='save_btn' onClick={searchFilter}>{t("search")}</button>
        </div>
        </div>
        </div>
        <div className='announcment_btns'>
                <div className='active_btns_box'>
                <div  className='active_btns_item'>
                    <div className='client_card_text active_text'/>
                    <p> - {t("active_adds")}</p>
                    </div>
                    <div  className='active_btns_item'>
                    <div className='client_card_text denger_text'/>
                    <p> - {t("no_active_adds")}</p>
                    </div>
                
                </div>
                <div className='filter_btns active_btns_btn'>
                    {selected.length===0?<Tooltip placement="bottom" title={t("choose_add_for_activate")}>
                <button className='restore_btn'> {t("activete")} <span><BiSolidBellRing /></span></button>
                </Tooltip>:
                <>
                <p className='selected_text_num'>{selected.length} {t("chosed_adds_number")}</p>
                <Tooltip placement="bottom" title={t("restore_choosed")}>
                <button onClick={()=>setselected([])} className='restore_btn'>{t("restore_choos")} <span><BiSolidBellRing /></span></button>
                </Tooltip>
                <Tooltip placement="bottom" title={t("tool_active")}>
                <button  onClick={activeHomes} className='success_btn'>{t("activete")} <span><BiSolidBellRing /></span></button>
                </Tooltip>
                 </>}
                
                <Link to={'create'}>
                   <button className='save_btn'> {t("add_elon")} <span><IoAddCircle /></span></button>
                   </Link>
                 </div>
            </div>
       <div className='client_card_box'>
        <Row>
            {data.map((item, key)=>{
                return( <CardClientCarCreditMini key={key} data={item} deleteHome={deleteHome} unActiveHome={unActiveHome} setselected={setselected}  selected={selected} setnumber={setnumber} number={number}/>)
            })}
           
        </Row>
        {mini_loader?
        <div className='mini_loader_box'><ClipLoader size={50} color="#e19e5e" /></div>
:<></>}
       </div>
    </div>
  )
}
