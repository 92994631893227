
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { carous_home_data } from '../../../components/Data';
import { Link } from 'react-router-dom';
import { IoMdArrowForward } from 'react-icons/io';
import { IoArrowForwardOutline } from 'react-icons/io5';

export default function CoruselHome(){
    const [active, setactive]=useState(0)
    const {t}=useTranslation()
    return(
        <div className="carous">
            <div className='carousel_item'>
                <div className='carousel_img'>
                {carous_home_data.map((item,key)=>{
                return(<div className={`carousel_img_item ${key===active?"carousel_img_active":''}`} style={{backgroundImage:`url(${item.image})`}}></div>)
                  })}
                </div>
                <div className='carousel_text'>
                     <div className='carousel_text_head'>
                     {carous_home_data.map((item,key)=>{
                return(<div onClick={()=>{setactive(key)}} className={`carousel_text_head_item ${key===active?"carousel_text_head_active":''}`}>{item.car_title[t("lang") as 'uz' | 'ru']}</div>)
                  })}
                     </div>
                     <div className='carousel_text_box'>
                     {carous_home_data.map((item,key)=>{
                return(<div className={`carousel_text_data ${key===active?"carousel_text_data_active":''}`}>
                    <h1>{item.title[t("lang") as 'uz' | 'ru']}</h1>
                    <p>{item.text[t("lang") as 'uz' | 'ru']}</p>
                   <div><Link className='carousel_btn' to="/products?search=&big_category=1&category=1&makler=null&furnished=null&price__gte=&price__lte=&floor__gte=&floor__lte=&rooms__gte=&rooms__lte=&year__gte=&year__lte=&surface__gte=&surface__lte=&house_type__in=null&mat_type__in=null&b_type__in=null&region=0&district__in=null&repair__in=null&near__in=null">
                       <span><IoArrowForwardOutline /></span>
                       <p>{t("see_homes")}</p>
                    </Link></div> 
                 </div>)
                  })}
                   </div>
                </div>
            </div>
           
        </div>
    )
}