'use client'
import React, { useState, useEffect, useCallback, useRef } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import { Thumb } from './EmblaCarouselThumbsButton'

import '../../css/base.css'
import '../../css/sandbox.css'
import '../../css/embla.css'
import { EmblaOptionsType } from 'embla-carousel'
import ImageBox from '../ImageBox'
import { ImageObject } from '../../store/Store'
import { Image } from 'antd';


type PropType = {
  images:ImageObject[]
  options?: EmblaOptionsType
  video?:string | null
  title:string | null
}

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { options, images, video, title } = props
  const [selectedIndex, setSelectedIndex] = useState(0)
 
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel(options)
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
    axis: 'y'
  })

  const onThumbClick = useCallback(
    (index: number) => {
      if (!emblaMainApi || !emblaThumbsApi) return
      emblaMainApi.scrollTo(index)
    },
    [emblaMainApi, emblaThumbsApi]
  )

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return
    setSelectedIndex(emblaMainApi.selectedScrollSnap())
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap())
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex])

  useEffect(() => {
    if (!emblaMainApi) return
    onSelect()
    emblaMainApi.on('select', onSelect)
    emblaMainApi.on('reInit', onSelect)
    
  }, [emblaMainApi, onSelect])

 
  return (
    <Image.PreviewGroup>
      <div className="embla">
     <div className="embla-thumbs">
        <div className="embla-thumbs__viewport" ref={emblaThumbsRef}>
          <div className="embla-thumbs__container">
            {images.map((item, key) => (
              <Thumb
                onClick={() => onThumbClick(key)}
                selected={key === selectedIndex}
                index={key}
                title={title}
                imgSrc={item.image}
                type='image'
                key={key}
              />
            ))}
            {video!=null?<Thumb
                onClick={() => onThumbClick(images.length)}
                selected={images.length === selectedIndex}
                index={images.length}
                imgSrc={video}
                title={title}
                type='video'
                key={images.length}
              />:<></>}
          </div>
        </div>
      </div>
      <div className="embla__viewport" ref={emblaMainRef}>
        <div className="embla__container">
          {images.map((item, index) => (
            <div className="embla__slide" key={index}>
              <div className="embla__slide__number">
                <span>{index + 1}</span>
              </div>
              <Image
                className="embla__slide__img"
                src={item.image}
                alt={title!=null?title:'Avtomobil va Uylar'}
              />
            </div>
          ))}
          {video!=null?<div className="embla__slide" key={images.length}>
              <div className="embla__slide__number">
                <span>{images.length+1}</span>
              </div>
              <video controls src={video}/>
            </div>:<></>}
        </div>
      </div>
    </div>
    </Image.PreviewGroup>
  )
}

export default EmblaCarousel
