import { Col, Row} from 'antd'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import SimpleSelect from '../../../../components/SimpleSelect'
import { API_KEY, ImageType, closeSelect} from '../../../../components/Data'
import SimpleInput from '../../../../components/SimpleInput'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill/dist/quill.core.css';
import SimpleInputText from '../../../../components/SimpleInputText'
import { IoCloseCircleSharp, IoSearch } from 'react-icons/io5'
import { useStore } from '../../../../store/Store'
import { FaTriangleExclamation } from 'react-icons/fa6'
import { dangerAlert, scrollDivToTop } from '../../../../components/Alert'
import { httpDeleteRequest, httpGetRequest, httpPatchRequest, httpPostRequest } from '../../../../host/Host'
import { useNavigate, useParams } from 'react-router-dom'
import SimpleInputPhone from '../../../../components/SimpleInputPhone'
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps'
import { useTranslation } from 'react-i18next'
export default function HomeCreditAnnoucmentEdit() {
  const {region_data, district_data, setdistrict_data, credit_type_data, b_type_data}=useStore()
  const [itype, setitype]=useState(0)
  const [b_type, setb_type]=useState(0)
  const [name, setname]=useState('')
  const [images, setimages]=useState<File[]>([])
  const [video, setvideo]=useState<File[]>([])
  const [region, setregion]=useState(0)
  const [district, setdistrict]=useState(0)
  const [address, setaddress]=useState('')
  const [facebook, setfacebook]=useState('')
  const [instagram, setinstagram]=useState('')
  const [website, setwebsite]=useState('')
  const [telegram, settelegram]=useState('')
  const [phone, setphone]=useState('')
  const [summ_min, setsumm_min]=useState<number|string>('')
  const [summ_max, setsumm_max]=useState<number|string>('')
  const [month_min, setmonth_min]=useState<number|string>('')
  const [month_max, setmonth_max]=useState<number|string>('')
  const [init_pay_percent_min, setinit_pay_percent_min]=useState<number|string>('')
  const [bonus_month, setbonus_month]=useState<number|string>('')
  const [init_pay_percent_max, setinit_pay_percent_max]=useState<number|string>('')
  const [coors, setcoors]=useState([41.355178,69.287824])
  const [p_min, setp_min]=useState<number|string>('')
  const [p_max, setp_max]=useState<number|string>('')
  const [additional, setadditional] = useState<string>('');
    const [required, setrequired]=useState(false)
    const [number, setnumber]=useState(1)
    
    const [image, setimage]=useState<number|null>(null)
    const [old_image_id, setold_image_id]=useState<number|null>(null)
    
    const [images_old, setimages_old]=useState<ImageType[]>([])
    const [images_delete, setimages_delete]=useState<number[]>([])
    
    const [video_str, setvideo_str]=useState(null)
   
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const {setloader}=useStore()
    const navigate=useNavigate()
    const videoInputRef = useRef<HTMLInputElement | null>(null);
    const {id}=useParams()
    const {t}=useTranslation()
    useEffect(()=>{
     if(isNaN(Number(id))){
      navigate("/client/home/credit")
     }
     
     
     getData()
    }, [])
    const getData=async()=>{
      setloader(true)
      try{
         var res=await httpGetRequest(`/ipoteka/${id}/detail/`)
         if(res.data.itype!=null){
          setitype(res.data.itype.id)
         }
         if(res.data.b_type!=null){
          setb_type(res.data.b_type.id)
         }
       
         if(res.data.video!==null){
          setvideo_str(res.data.video)
         }
         if(res.data.name!=null){
         setname(res.data.name)
         }
         if(res.data.image!=null){
          setold_image_id(res.data.image.id)
          }
         if(res.data.instagram!=null){
         setinstagram(res.data.instagram)
         }
         if(res.data.additional!=null){
         setadditional(res.data.additional)
         }
         if(res.data.facebook!=null){
         setfacebook(res.data.facebook)
         }
        
         if(res.data.phone!=null){
         setphone(res.data.phone)
         }
         if(res.data.summ_min!=null){
          setsumm_min(Number(res.data.summ_min))
          }
          if(res.data.summ_max!=null){
          setsumm_max(Number(res.data.summ_max))
          }
          if(res.data.init_pay_percent_min!=null){
            setinit_pay_percent_min(Number(res.data.init_pay_percent_min))
            }
            if(res.data.init_pay_percent_max!=null){
            setinit_pay_percent_max(Number(res.data.init_pay_percent_max))
            }
            if(res.data.month_min!=null){
              setmonth_min(Number(res.data.month_min))
              }
              if(res.data.month_max!=null){
              setmonth_max(Number(res.data.month_max))
              }
            if(res.data.p_min!=null){
              setp_min(Number(res.data.p_min))
              }
            if(res.data.bonus_month!=null){
                setbonus_month(Number(res.data.bonus_month))
            }
            if(res.data.p_max!=null){
            setp_max(Number(res.data.p_max))
            }
        
         if(res.data.region!=null){
         setregion(res.data.region.id)
         }
         if(res.data.district!=null){
         setdistrict(res.data.district.id)
         }
        
         if(res.data.website!=null){
          setwebsite(res.data.website)
          }
          if(res.data.image!=null){
            setimage(res.data.image.id)
          }
          
          if(res.data.telegram!=null){
            settelegram(res.data.telegram)
            }
            
         
        
         if(res.data.region!=null){
          handleregion(res.data.region.id, 1)
         }
         if(res.data.images!=null){
            setimages_old(res.data.images)
         }
        
         setloader(false)
         setnumber(number+1)
      }catch(err){
        setloader(false)
         navigate("/client/home/credit")
      }
    }
    const handleSearch = async () => {
      if (address) {
        try {
          const response = await fetch(
            `https://geocode-maps.yandex.ru/1.x/?apikey=${API_KEY}&format=json&geocode=${encodeURIComponent(
              address
            )}`
          );
          const data = await response.json();
          if(data.response.GeoObjectCollection.featureMember.length>0){
            const pos =
            data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos;
          const [lon, lat] = pos.split(" ").map(Number);
          console.log([lon, lat])
          setcoors([lat, lon]); 
          }
          
        } catch (error) {
          console.error("Error fetching coordinates:", error);
        }
      }
    };
    const onFinish=async()=>{
        if(phone!=null){
          var phone_new=phone.replaceAll("_", "")
        }else{
          var phone_new=""
        }
        
        if(name.length!==0 && phone_new.length===19 && region!==0 && district!==0 && summ_min!=='' && summ_max!==''  && month_min!=='' && month_max!==''  && p_min!=='' && p_max!=='' && itype!==0 ){
          if(!(images.length!==0 || images_old.length!==0)){
            dangerAlert(t("not_images"))
          }else{
            setloader(true)
            var data={
              name,
              bonus_month:!isNaN(Number(bonus_month))?String(bonus_month):null,
              init_pay_percent_min:!isNaN(Number(init_pay_percent_min))?String(init_pay_percent_min):null,
              init_pay_percent_max:!isNaN(Number(init_pay_percent_max))?String(init_pay_percent_max):null,
              summ_min:!isNaN(Number(summ_min))?String(summ_min):null,
              summ_max:!isNaN(Number(summ_max))?String(summ_max):null,
              month_min:!isNaN(Number(month_min))?String(month_min):null,
              month_max:!isNaN(Number(month_max))?String(month_max):null,
              p_min:!isNaN(Number(p_min))?String(p_min):null,
              p_max:!isNaN(Number(p_max))?String(p_max):null,
              latitude:String(coors[0]),
              longitude:String(coors[1]),
              phone,
              instagram:instagram.length===0?null:instagram,
              facebook:facebook.length===0?null:facebook,
              website:website.length===0?null:website,
              telegram:telegram.length===0?null:telegram,
              region:region!==0?String(region):null,
              district:district!==0?String(district):null,
              itype:itype!==0?String(itype):null,
              b_type:b_type!==0?String(b_type):null,
              additional,
              video:video.length===0?null:video[0],
              image:image!==null?String(image):old_image_id!==null?String(old_image_id):null,
            }
            const formData = new FormData();
            
            for (const [key, value] of Object.entries(data)) {
              if (value !== null) {
                formData.append(key, value);
                
              }
            }
            try{
              var res=await httpPatchRequest(`/ipoteka/${id}/update/`, formData)
              if(images_delete.length!==0){
                await Promise.all(images_delete.map(async(item2:number, key:number)=>{
                  try{
                    var res2=await httpDeleteRequest(`/ipoteka/images/${item2}/delete/`)
                  }catch(err2){
                    console.log(err2)
                  }
                }))
              }
              if(images.length!=0){
                await Promise.all(images.map(async(item, key:number)=>{
                  var config=new FormData
                  config.append('image', item)
                  config.append('ipoteka', res.data.id)
                  config.append('main', 'false')
                  try{
                    var res1=await httpPostRequest(`/ipoteka/images/create/`, config)
                    
                  }catch(err1){
                    console.log(err1)
                  }
                }))
               
              }
             
              setloader(false)
              navigate("/client/home/credit")
            }catch(err){
              setloader(false)
              dangerAlert(t("Error"))
              console.log(err)
            }
          
          }
         
        }else{
          dangerAlert(t("error_not_full"))
          setrequired(true)
          scrollDivToTop()
        }
    }
   
    const handleEditorChange = (content: string) => {
        setadditional(content);
      };
      const handlechange=()=>{

      }
      const deleteVideo=async()=>{
        try{
         setloader(true)
          var res=await httpDeleteRequest(`/ipoteka/video/${id}/delete/`)   
          setvideo_str(null)
          setloader(false)
        }catch(err){
            setloader(false)
        }
 }
    const handleVideoChange = (e: ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = e.target.files;
    
      if (selectedFiles) {
        var filesArray=[]
        for(let i=0; i<selectedFiles.length; i++){
          const fileSizeInBytes = selectedFiles[i].size;
          const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024);
          if (fileSizeInMegabytes <= 100) {
            filesArray.push(selectedFiles[i])
          }
           
        }
        if(filesArray.length!==selectedFiles.length){
          dangerAlert(t("error_video_mb"))
        }
        
        setvideo(filesArray)
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    };
   
      const handleCloseSelect=(e: React.MouseEvent<HTMLDivElement>)=>{
     
        if (e.target instanceof HTMLElement && e.target.className.indexOf("select")===-1 && e.target.parentElement instanceof HTMLElement && e.target.parentElement.className.indexOf("select")===-1) {
          // Handle the body click here
          closeSelect("a");
        }
       }
       const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
         
        if (selectedFiles) {
          var filesArray=[]
          for(let i=0; i<selectedFiles.length; i++){
            const fileSizeInBytes = selectedFiles[i].size;
            const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024);
            if (fileSizeInMegabytes <= 10) {
              filesArray.push(selectedFiles[i])
            }
             
          }
          if(filesArray.length!==selectedFiles.length){
            dangerAlert(`${t("lang")==='uz'?selectedFiles.length-filesArray.length:''} ${t("error_image_mb")}`)
          }
          
          var l=10-(images.length+images_old.length)
          if(l<0){
            l=0
          }
          setimages([...filesArray.slice(0, l), ...images]);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }
      };
      const deleteImage=(key:number)=>{
        var a=images
        a.splice(key,1)
setimages(a)
setnumber(number+1)
      }
      const deleteImage_old=async(key:number)=>{
          var id_im=images_old[key].id       
          setimages_delete([...images_delete, images_old[key].id])
          var a=images_old
          a.splice(key,1)
  setimages_old(a)
  
  setnumber(number+1)
  if(image===id_im){
    setActiveImage()
  }
        
      }
      const setActiveImage=()=>{
        if(images_old.length!==0){
          setimage(images_old[0].id)
        }else{
            setimage(0)
        }
      }
      const handleregion=async(id_new:number, bol?:number)=>{
       
        if(bol===undefined){
          
          setdistrict(0)
        }
        
        setdistrict_data([])
        var res=await httpGetRequest('/districts/?region='+id_new)
         setdistrict_data(res.data)
    
      }
      const handleClick = (e: any) => {
        const coords = e.get('coords');
        setcoors(coords);
      };
  return (
    <div  onClick={(e)=>{handleCloseSelect(e)}} className='announcment_client'>
        <div className={`announcment_filter client_open_filter`}>
        <h1 className='create_title'>{t("edit_elon")}</h1>
           
            <div className='announcment_filter_body'>
            <div className='alert_text'> <FaTriangleExclamation className='alert_icon' />{t("alert_add_elon")}</div>
            <Row>
            <Col  className='filter_col' lg={8} md={24} sm={24}>
                <SimpleInputText max={70} required={required} placeholder={""} label={t("name_add")} value={name} setvalue={setname}/>
                </Col>
                <Col className='filter_col' lg={8} md={12} sm={24}>
            <SimpleSelect required={required} onchange={handlechange} name="itype" label={t("credit_type")} value={itype} setvalue={setitype} data={credit_type_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
            <SimpleSelect onchange={handlechange} name="b_type" label={"Bozor turi"} value={b_type} setvalue={setb_type} data={b_type_data}/>
            </Col>
               
                <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect required={required} onchange={handleregion} name="region" label= {t("region")} value={region} setvalue={setregion} data={region_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
            <SimpleSelect required={required} onchange={handlechange} name="district" label= {t("district")} value={district} setvalue={setdistrict} data={district_data}/>
            </Col>
           
           
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput placeholder={" "} label={t("bonus_month")} value={bonus_month} setvalue={setbonus_month}/>
            </Col>

            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput required={required} placeholder={" "} label={t("credit_month_min")} value={month_min} setvalue={setmonth_min}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput required={required} placeholder={" "} label={t("credit_month_max")} value={month_max} setvalue={setmonth_max}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput required={required} placeholder={" "} label={t("credit_summ_min")} value={summ_min} setvalue={setsumm_min}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput required={required} placeholder={" "} label={t("credit_summ_max")} value={summ_max} setvalue={setsumm_max}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput percent={true} required={required} placeholder={" "} label={t("credit_percent_min")} value={p_min} setvalue={setp_min}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput  percent={true} required={required} placeholder={" "} label={t("credit_percent_max")} value={p_max} setvalue={setp_max}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput  percent={true} placeholder={" "} label={t("credit_init_percent_min")} value={init_pay_percent_min} setvalue={setinit_pay_percent_min}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput  percent={true} placeholder={" "} label={t("credit_init_percent_max")} value={init_pay_percent_max} setvalue={setinit_pay_percent_max}/>
            </Col>
           
        
            <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputPhone required={required} placeholder={""} label= {t("phone")} value={phone} setvalue={setphone}/>
                </Col>
                <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label= {t("Telegram")} value={telegram} setvalue={settelegram}/>
                </Col>
                <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label= {t("Instagram")} value={instagram} setvalue={setinstagram}/>
                </Col>
                <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label= {t("FaceBook")} value={facebook} setvalue={setfacebook}/>
                </Col>
                <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label= {t("Vebsite")} value={website} setvalue={setwebsite}/>
                </Col>
                <Col lg={16} md={12} sm={24}></Col>
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}>
            <label>{t("select_address")}</label>
            <div className='yandex_box'>
            <div className='yandex_input'>
            <SimpleInputText required={required} handlesearch={handleSearch}  placeholder={t("address")} label={t("")} value={address} setvalue={setaddress}/>
            <button onClick={handleSearch}><IoSearch /></button>
            </div>   
            <YMaps>
            <Map onClick={handleClick} state={{
        center: coors,
        zoom: 14,
      }} width="100%" height="100%" options={{
        minZoom: 5,
        maxZoom: 20,
      }}>
        <Placemark geometry={coors} properties={{ hintContent: 'My Placemark', balloonContent: 'This is my placemark!' }} />
        <ZoomControl options={{ float: 'left' }} />
      </Map>
   
    </YMaps>
    </div>
            </Col>
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}>
            <label>{t("additional")}</label>
           <div className='quill_box'> <ReactQuill
        theme="snow" // You can choose different themes like 'bubble' or 'snow'
        value={additional}
        onChange={handleEditorChange}
      /></div>
            </Col>
           
           {((images_old.length)+images.length)<10?  
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}>
            <label>{t("select_image")}</label>
            <input ref={fileInputRef} onChange={handleFileChange} className='file_input' accept="image/*" type='file' multiple />
            <p className='file_text'>{t("alert_image")}</p>
            </Col>:<Col className='filter_col filter_col_right' lg={12} md={24} sm={24}></Col>}
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}>
            <label>{t("select_video")}</label>
            <input ref={videoInputRef} onChange={handleVideoChange} className='file_input' accept="video/*" type='file' />
            <p className='file_text'>{t("alert_image")}</p>
            </Col>
            
            {images.map((item, key)=>{
                return(<Col key={key} className='filter_col' lg={6} md={12} sm={24}>
                    <div className='image_box'>
                       <img src={URL.createObjectURL(item)}/>
                       <div onClick={()=>{deleteImage(key)}} className='img_delete_btn'><IoCloseCircleSharp /></div>
                       
                    </div>
                    
                </Col>)
            })}
            {images_old.map((item, key)=>{
                return(<Col key={key} className='filter_col' lg={6} md={12} sm={24}>
                    <div className='image_box'>
                       <img src={item.image}/>
                       <div onClick={()=>{deleteImage_old(key)}} className='img_delete_btn'><IoCloseCircleSharp /></div>
                       <input className='active_image' onChange={()=>setimage(item.id)} type="radio" name="image" value={item.id} checked={item.id===image} />
                    </div>
                    
                </Col>)
            })}
              {video_str!==null?<Col className='filter_col' lg={12} md={12} sm={24}>
                    <div className='image_box video_box'>
                       <video src={video_str} controls />
                       <div onClick={()=>{deleteVideo()}} className='img_delete_btn'><IoCloseCircleSharp /></div>
                     </div>
                    
                </Col>:<></>}
            
        </Row>
        <div className='filter_btns'>
         
          <button onClick={onFinish} className='save_btn'>{t("save")}</button>
        </div>
        </div>
        </div>
       
    </div>
  )
}
