import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { IoMdArrowForward } from 'react-icons/io';

export default function Bests(){
    const {t}=useTranslation()
    return(
        <div className="bests">
        <h1 className='title'>{t("bests")}</h1>
        <Row className="best_row">
        <Col className="best_col" lg={12} md={24} sm={24}>
          <div className='best_card'>
                 <div className='best_img'>
                  <div className='best_img_img best_home'></div>
                 </div>
                 <div className='best_text'>
                  <h2>2-комн. евро 46 м2</h2>
                  <p>Арендный дом город Ташкент, Бурхониддин маргилоний ул, 70-дом / 48 кв.</p>
                  <h1>3 000 000 сум</h1>
                 </div>
                 <div className='best_arrow'>
                 <IoMdArrowForward />
                 </div>
             </div>
             <div className='best_card'>
                 <div className='best_img'>
                  <div className='best_img_img best_home'></div>
                 </div>
                 <div className='best_text'>
                  <h2>2-комн. евро 46 м2</h2>
                  <p>Арендный дом город Ташкент, Бурхониддин маргилоний ул, 70-дом / 48 кв.</p>
                  <h1>3 000 000 сум</h1>
                 </div>
                 <div className='best_arrow'>
                 <IoMdArrowForward />
                 </div>
             </div>
             <div className='best_card'>
                 <div className='best_img'>
                  <div className='best_img_img best_home'></div>
                 </div>
                 <div className='best_text'>
                  <h2>2-комн. евро 46 м2</h2>
                  <p>Арендный дом город Ташкент, Бурхониддин маргилоний ул, 70-дом / 48 кв.</p>
                  <h1>3 000 000 сум</h1>
                 </div>
                 <div className='best_arrow'>
                 <IoMdArrowForward />
                 </div>
             </div>
          </Col>
          <Col className="best_col" lg={12} md={24} sm={24}>
          <div className='best_card'>
                 <div className='best_img'>
                  <div className='best_img_img best_car'></div>
                 </div>
                 <div className='best_text'>
                  <h2>Chevrolet Tracker, 3 позиция</h2>
                  <p>Город Ташкент, 3 позиция, автомат, кроссовер. 2019год, спереди, бензин</p>
                  <h1>190 000 000 сум</h1>
                 </div>
                 <div className='best_arrow'>
                 <IoMdArrowForward />
                 </div>
             </div>
             <div className='best_card'>
                 <div className='best_img'>
                  <div className='best_img_img best_car'></div>
                 </div>
                 <div className='best_text'>
                  <h2>Chevrolet Tracker, 3 позиция</h2>
                  <p>Город Ташкент, 3 позиция, автомат, кроссовер. 2019год, спереди, бензин</p>
                  <h1>190 000 000 сум</h1>
                 </div>
                 <div className='best_arrow'>
                 <IoMdArrowForward />
                 </div>
             </div>
             <div className='best_card'>
                 <div className='best_img'>
                  <div className='best_img_img best_car'></div>
                 </div>
                 <div className='best_text'>
                  <h2>Chevrolet Tracker, 3 позиция</h2>
                  <p>Город Ташкент, 3 позиция, автомат, кроссовер. 2019год, спереди, бензин</p>
                  <h1>190 000 000 сум</h1>
                 </div>
                 <div className='best_arrow'>
                 <IoMdArrowForward />
                 </div>
             </div>
          </Col>
        </Row>
      </div>
    )
}