import React from 'react'
import { IoIosArrowDown } from 'react-icons/io';
import { closeSelect } from './Data';

import empty_data from '../images/empty_data.png'
import ImageBox from './ImageBox';
import { TypeObject } from '../store/Store';
import { useTranslation } from 'react-i18next';

  
  interface SimpleSelectProps {
    label:string;
    name:string;
    onchange: (setvalue: number) => void;
    value: number;
    setvalue: (setvalue: number) => void;
    data: TypeObject[];
    required?:boolean;
  }
  const SimpleSelect: React.FC<SimpleSelectProps>=({value, setvalue, data, label, name, onchange, required=false})=>{
    const {t}=useTranslation()

    const setValueForElement = () => {
      if(data!==undefined){
        const selectedOption = data.find((option) => option.id === value);
  
        if (selectedOption) {
          return selectedOption[`name_${t("lang")==='ru'?'ru':'uz'}`];
        } else {
          return t("choose");
        }
      }
      
    };
    const editSelect=()=>{
      var a=document.querySelector(`.select_${name}`)
      var g=closeSelect(`select_${name}`)
      
      if(a && g){
          a.classList.add('select_active');
      }
    }
    const setBoolForElement = () => {
      if(data!==undefined){
        const selectedOption = data.find((option) => option.id === value);
  
        if (selectedOption && value!==0) {
          return '';
        } else {
          return "choose";
        }
      }
      
    };
    
  return (
    <div className={`select select_${name} ${required && (value===null || value===0)?"red_select":""}`}>
        {label.length!==0?<label>{label}</label>:<></>}
        
        <div onClick={editSelect} id={setBoolForElement()} className='select_input'>
            <p>{setValueForElement()}</p><span><IoIosArrowDown /></span>
        </div>
        <div className='select_box'>
        <div className='select_box_in'>

        {data!==undefined && data.length!==0?data.map((item, key: number) => (
            <div onClick={()=>{setvalue(item.id); closeSelect(`select_${name}`); onchange(item.id)}} key={key} className={`select_box_item ${item.id===value?"active_select_box_item":""}`}>
              {item[`name_${t("lang")==='ru'?'ru':'uz'}`]}
            </div>
          )):<div className='select_empty'><ImageBox alt='image' src={empty_data}/></div>}
        
 </div>
       </div>
        
    </div>
  )
}
export default SimpleSelect;