import React, { useState } from 'react'
import { IoIosArrowDown} from 'react-icons/io';
import { closeSelect } from './Data';

import empty_data from '../images/empty_data.png'
import { IoClose } from 'react-icons/io5';
import ImageBox from './ImageBox';
import { TypeObject } from '../store/Store';
import { useTranslation } from 'react-i18next';

  
  interface MultipleSelectProps {
    label:string;
    name:string;
    
    value: string | null;
    setvalue: (setvalue: string | null) => void;
    data: TypeObject[];
  }
  const MultipleSelect: React.FC<MultipleSelectProps>=({value, setvalue, data, label, name})=>{
   const [number, setnumber]=useState(0)
   const {t}=useTranslation()
   
    const setValueForElement = () => {
      if(value===null){
        return t("all");
      }else{
        if(value.length===2){
          return(t("not_select"))
        }else{
          if(value.replaceAll('[', '').replaceAll('[', '').split(",").length!==data.length){
            return t("choosed_items");
          }else{
            return t("all");
          }
          
        }
       
      }
      
    };
    const editSelect=()=>{
      var a=document.querySelector(`.select_${name}`)
      var g=closeSelect(`select_${name}`)
      
      if(a && g){
          a.classList.add('select_active');
      }
    }
    const setBoolForElement = () => {
       if(value!==null){
        if(value.length===2){
          return t("choose");
        }else{
          return '';
        }
        }else{
       return t("choose");
        }
        
      }
    
    const handleChange=(id:number)=>{
      var a=[]   
      if(value!==null){
         a=JSON.parse(value.replace(/'/g, '"'))
      }
    
      if(a.find((x:number)=>x===id)){
        a.splice(a.indexOf(id), 1)
      }else{
        a.push(id)
      }
     
      if(a.length!==0){
        setvalue(JSON.stringify(a))
      }else{
        if(a.length===0){
          setvalue('[]')
        }
      }
     
      setnumber(number+1)
    }

    const checkChecked = (id: number) => {
   const a = value !== null ? JSON.parse(value.replace(/'/g, '"')) : [id];
      return a.includes(id);
    };
const setAllValue=(t:boolean)=>{
  if(!t){
    setvalue(JSON.stringify(data.map(item=>(item.id))))
  }else{
    setvalue('[]')
  }
  
 
  
}
  return (
    <div className={`select select_${name}`}>
      <p style={{display:'none'}}>{number}</p>
        <label>{label}</label>
        <div onClick={editSelect} id={setBoolForElement()} className='select_input'>
            <p>{setValueForElement()}</p>
            {value===null || value.length===2?<span><IoIosArrowDown /></span>:<span className='close_icon' onClick={()=>{setvalue('[]');}}><IoClose /></span>}
            
        </div>
        <div className='select_box'>
        <div className='select_box_in'>
       
        {data.length!==0?
        <> <div onClick={()=>{setAllValue(value===null||JSON.parse(value.replace(/'/g, '"')).length===data.length)}} className={`select_box_item`}>
        <input className='check_input' onChange={()=>{}} checked={!(value!==null && JSON.parse(value.replace(/'/g, '"')).length!==data.length)} type="checkbox"/>
        <span>{t("all")}</span>
       </div>
       {data.map((item, key: number) => (
            <div onClick={()=>{handleChange(item.id)}} key={key} className={`select_box_item`}>
             <input className='check_input'  onChange={()=>{}} checked={checkChecked(item.id)} type="checkbox"/>
             <span>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>
          ))}
       </>
        :<div className='select_empty'><ImageBox alt='image' src={empty_data}/></div>}
        
 </div>
       </div>
        
    </div>
  )
}
export default MultipleSelect;