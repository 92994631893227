
import { IoHome, IoMenu, IoSettingsSharp } from 'react-icons/io5'
import { Link, useLocation, useNavigation } from 'react-router-dom'
import { FaCar } from 'react-icons/fa6';
import { GiAutoRepair, GiCarWheel, GiTakeMyMoney } from 'react-icons/gi';
import { RiPaintBrushFill } from 'react-icons/ri';
import { GrPaint } from "react-icons/gr";
import { useStore } from '../../store/Store';
import avatar from '../../images/icons/random_avatar_man.jpg'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import home from '../../images/menu_icons/home.png'
import home_service from '../../images/menu_icons/home_service.png'
import home_product from '../../images/menu_icons/home_product.png'
import home_credit from '../../images/menu_icons/home_credit.png'
import car from '../../images/menu_icons/car.png'
import car_credit from '../../images/menu_icons/car_credit.png'
import car_product from '../../images/menu_icons/car_product.png'
import setting from '../../images/menu_icons/setting.png'
import car_service from '../../images/menu_icons/car_service.png'
export default function SideBar() {
    const {user}=useStore()
    let location = useLocation();
    const [loc, setloc]=useState(1)
    const [open, setopen]=useState(true)
    const {t} =useTranslation()
    useEffect(()=>{
       
            if(location.pathname.indexOf('/home/home')!==-1){
                setloc(2)
            }else{
                if(location.pathname.indexOf('home/product')!==-1){
                    setloc(4)
                }else{
                    if(location.pathname.indexOf('car/product')!==-1){
                        setloc(8)
                    }else{
                        if(location.pathname.indexOf('/car/car')!==-1){
                            setloc(6)
                        }else{
                            if(location.pathname.indexOf('/home/service')!==-1){
                                setloc(3)
                            }else{
                                if(location.pathname.indexOf('/car/service')!==-1){
                                    setloc(7)
                                }else{
                                    if(location.pathname.indexOf('/home/credit')!==-1){
                                        setloc(5)
                                    }else{
                                        if(location.pathname.indexOf('/car/credit')!==-1){
                                            setloc(9)
                                        }else{
                                            setloc(1)
                                        }
                                    }
                                    
                                }
                                
                            }
                           
                        }
                       
                    }
                    
                }
                
            }
           if(window.innerWidth<=767){
            setopen(false)
           }
        
    }, [location])
  return (
    <>
    <div className={`client_menu ${open?"open_side_bar":''}`}>
    <div className={`client_menu_header`}>
   
            <button onClick={()=>setopen(!open)} className='menu_tab'>
            <IoMenu />
            </button>
    </div>
    <div className='client_menu_list'>
    <Link className={`client_list_item ${loc===1?"active_list_item":''}`} to="">
    <img src={setting}/>
            <p>{t("parametr")}</p>
        </Link>
       
       
        {/* <Link className={`client_list_item ${loc===1?"active_list_item":''}`} to="announcement">
            <span><FaMoneyBillWave /></span>
            <p>To'lovlar</p>
        </Link> */}
        <div className='client_dropdown'>
        <div className='client_list_item client_dropdown_head'>
            <p>{t("houses")}</p>
        </div>
        <Link className={`client_list_item ${loc===2?"active_list_item":''}`} to="home/home">
            <img src={home}/>
            <p>{t("houses")}</p>
        </Link>
        <Link className={`client_list_item ${loc===3?"active_list_item":''}`} to="home/service">
        <img src={home_service}/>
            <p>{t("master_service")}</p>
        </Link>
        <Link className={`client_list_item ${loc===4?"active_list_item":''}`} to="home/product">
        <img src={home_product}/>
            <p>{t("material")}</p>
        </Link>
        <Link className={`client_list_item ${loc===5?"active_list_item":''}`} to="home/credit">
        <img src={home_credit}/>
            <p>{t("ipoteka")}</p>
        </Link>
        </div>

        <div className='client_dropdown'>
        <div className='client_list_item client_dropdown_head'>
            <p>{t("cars")}</p>
        </div>
        <Link className={`client_list_item ${loc===6?"active_list_item":''}`} to="car/car">
        <img src={car}/>
            <p>{t("cars")}</p>
        </Link>
        <Link className={`client_list_item ${loc===7?"active_list_item":''}`} to="car/service">
        <img src={car_service}/>
            <p>{t("master_service_car")}</p>
        </Link>
        <Link className={`client_list_item ${loc===8?"active_list_item":''}`} to="car/product">
        <img src={car_product}/>
            <p>{t("material_car")}</p>
        </Link>
        <Link className={`client_list_item ${loc===9?"active_list_item":''}`} to="car/credit">
        <img src={car_credit}/>
            <p>{t("auto_credit")}</p>
        </Link>
        </div>
      
        
        
        
    </div> 
    </div>
    </>
    
  )
}
