
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { carous_car_data } from '../../../components/Data';
import { Link } from 'react-router-dom';
import { IoMdArrowForward } from 'react-icons/io';
import { IoArrowForwardOutline } from 'react-icons/io5';

export default function CoruselCar(){
    const [active, setactive]=useState(0)
    const {t}=useTranslation()
    return(
        <div className="carous car_carous">
            <div className='carousel_item'>
                
                <div className='carousel_text'>
                     <div className='carousel_text_head'>
                     {carous_car_data.map((item,key)=>{
                return(<div onClick={()=>{setactive(key)}} className={`carousel_text_head_item ${key===active?"carousel_text_head_active":''}`}>{item.car_title[t("lang") as 'uz' | 'ru']}</div>)
                  })}
                     </div>
                     <div className='carousel_text_box'>
                     {carous_car_data.map((item,key)=>{
                return(<div className={`carousel_text_data ${key===active?"carousel_text_data_active":''}`}>
                    <h1>{item.title[t("lang") as 'uz' | 'ru']}</h1>
                    <p>{item.text[t("lang") as 'uz' | 'ru']}</p>
                   <div><Link className='carousel_btn' to="/products?search=&big_category=2&category=5&autosalon=null&rent=null&long_term_pay=null&price__gte=&price__lte=&probeg__gte=&probeg__lte=&year__gte=&year__lte=&cmtype=0&ctype=0&model=0&position__in=null&b_type__in=null&region=0&district__in=null&kuzov__in=null&transmission__in=null&drive_unit__in=null&color__in=null&color_state__in=null&engine_type__in=null">
                       <span><IoArrowForwardOutline /></span>
                       <p>{t("see_cars")}</p>
                    </Link></div> 
                 </div>)
                  })}
                   </div>
                </div>
                <div className='carousel_img'>
                {carous_car_data.map((item,key)=>{
                return(<div className={`carousel_img_item ${key===active?"carousel_img_active":''}`} style={{backgroundImage:`url(${item.image})`}}></div>)
                  })}
                </div>
            </div>
           
        </div>
    )
}