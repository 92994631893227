
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useStore } from '../store/Store';
import { motion, Variants } from "framer-motion";
import {IoLogOut, IoNewspaperOutline, IoSettingsSharp } from 'react-icons/io5';
import avatar from '../images/icons/random_avatar_man.jpg'
import logo from '../images/logo.png'
import { useTranslation } from 'react-i18next';
import i18n from '../locale/i18n';
import cookie from 'react-cookies'
import { MdArrowForwardIos } from "react-icons/md";
import React, { useEffect, useState } from 'react';
import { FiUser } from "react-icons/fi";
import { LuSearch } from "react-icons/lu";
import { IoIosArrowForward } from 'react-icons/io';
const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 }
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
};
type HoverData = {
  [key: string]: boolean;
};
export default function Navbar() {
  const { user, setuser}=useStore()
  const [searchParams, setSearchParams] = useSearchParams();
  const {search, setsearch, house_type_data,
    house_service_types_data,
    house_product_type_data,
    credit_type_data,
    cmtype_data,
    open_search, setopen_search,isOpen, setisOpen,
    car_service_types_data,
    car_product_type_data}=useStore()

  const big_category = Number(searchParams.get('big_category'))
  const [hover_data, sethover_data]=useState<HoverData>({
    "cat_1":false,
    "cat_2":false,
    "cat_3":false,
    "cat_4":false,
    "cat_5":false,
    "cat_6":false,
    "cat_7":false,
    "cat_8":false,
})
  const [isMore, setisMore] = useState<boolean>(false);
  const navigate=useNavigate()
  const location=useLocation()
  const {t}=useTranslation()

  const logout=()=>{
    window.localStorage.removeItem("access_token_elon")
    setuser(null)
    navigate('/')
  }

  const changeLang=(til:string)=>{
    i18n.changeLanguage(til)
    cookie.save('tilElon', til, { path: '/' })
  }
  const handleMouseEnter = (id:number) => {
    const newHoverData: HoverData = {
      cat_1: false,
      cat_2: false,
      cat_3: false,
      cat_4: false,
      cat_5: false,
      cat_6: false,
      cat_7: false,
      cat_8: false,
    };
  
    newHoverData[`cat_${id}` as keyof HoverData] = true;
    sethover_data(newHoverData);
  };


  useEffect(()=>{
    const handleBodyClick = (e: MouseEvent | React.MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target && target.parentElement && target.parentElement.className!=='navbar_link' && target.className!=='navbar_link') {
        setisMore(false)
        sethover_data({
          "cat_1":false,
          "cat_2":false,
          "cat_3":false,
          "cat_4":false,
          "cat_5":false,
          "cat_6":false,
          "cat_7":false,
          "cat_8":false,
      })
      }
      
    };
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  },[])
    const editNavigate=(link:string)=>{
          if(location.pathname.indexOf('products')===-1){
              navigate(link)
          }else{
            let a=window.location.href
            window.location.href=a.slice(0, a.indexOf("/", 10))+link
          }
    }

    const goSearch=()=>{
      console.log(location)
      if(!open_search){
        setsearch('')
        setopen_search(true)
      }
      if(location.pathname.indexOf('products')===-1){
        navigate(`/products?search=${search}&big_category=1&category=1`)
    }else{
      navigate(`${location.pathname}?search=${search}&big_category=${location.search.slice(location.search.indexOf("big_category=")+13, location.search.indexOf("big_category=")+14)}&category=${location.search.slice(location.search.indexOf("&category=")+10, location.search.indexOf("&category=")+11)}`)
    }
   
    }
  return (
    <div className='big_navbar'>
      <div className='navbar_left'>
      <Link to="/" className='brand'>
         <img alt="..." src={logo}/>
        </Link>
        <ul>
        <li>
            <Link className={`navbar_link ${big_category==1?"active_link_nav":''}`} to={'/categories?big_category=1'}>{t("houses")}</Link>
          </li>
          <li>
            <Link className={`navbar_link ${big_category==2?"active_link_nav":''}`} to={'/categories?big_category=2'}> {t("cars")}</Link>
          </li>
          <li className='more'>
          <button onClick={()=>{setisMore(!isMore)}} className='navbar_link'><p>{t("more")}</p> <span><MdArrowForwardIos /></span></button>
          <div className={`more_list ${isMore?'open_drop_more':''}`}>
          <div className='more_big_list'>
                <Link onMouseEnter={()=>{handleMouseEnter(1)}} to="/selectors?big_category=1&category=1" className='more_item'>
                
                <p>{t("turar_joy")}</p>
                <span><IoIosArrowForward /></span>
                </Link>
                <Link onMouseEnter={()=>{handleMouseEnter(2)}} to="/selectors?big_category=1&category=2" className='more_item'>
                
                <p>{t("master_service")}</p>
                <span><IoIosArrowForward /></span>
                </Link>
                <Link onMouseEnter={()=>{handleMouseEnter(3)}} to="/selectors?big_category=1&category=3" className='more_item'>
                
                <p>{t("material")}</p>
                <span><IoIosArrowForward /></span>
                </Link>
                <Link onMouseEnter={()=>{handleMouseEnter(4)}} to="/selectors?big_category=1&category=4" className='more_item'>
                
                <p>{t("ipoteka")}</p>
                <span><IoIosArrowForward /></span>
                </Link>
                <Link onMouseEnter={()=>{handleMouseEnter(5)}} to="/selectors?big_category=2&category=5" className='more_item'>
                
                <p>{t("cars")}</p>
                <span><IoIosArrowForward /></span>
                </Link>
                <Link onMouseEnter={()=>{handleMouseEnter(6)}} to="/selectors?big_category=2&category=6" className='more_item'>
                
                <p>{t("master_service_car")}</p>
                <span><IoIosArrowForward /></span>
                </Link>
                <Link onMouseEnter={()=>{handleMouseEnter(7)}} to="/selectors?big_category=2&category=7" className='more_item'>
                
                <p>{t("material_car")}</p>
                <span><IoIosArrowForward /></span>
                </Link>
                <Link onMouseEnter={()=>{handleMouseEnter(8)}} to="/selectors?big_category=2&category=8" className='more_item'>
                
                <p>{t("auto_credit")}</p>
                <span><IoIosArrowForward /></span>
                </Link>
            </div> 
            <div style={{display:hover_data['cat_1']?'flex':'none'}} className='more_big_list_two more_big_list'>
            {house_type_data.map((item, key)=>{
                 return( <div onClick={()=>{editNavigate(`/products?big_category=1&category=1&house_type__in=[${item.id}]`)}} className='more_item'>
                  <p>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</p>
                  </div>)
            })}
             </div> 
             <div style={{display:hover_data['cat_2']?'flex':'none'}} className='more_big_list_two more_big_list'>
            {house_service_types_data.map((item, key)=>{
                 return( <div onClick={()=>{editNavigate(`/products?big_category=1&category=2&service_types__in=[${item.id}]`)}} className='more_item'>
                  <p>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</p>
                  </div>)
            })}
             </div> 
             <div style={{display:hover_data['cat_3']?'flex':'none'}} className='more_big_list_two more_big_list'>
            {house_product_type_data.map((item, key)=>{
                 return( <div onClick={()=>{editNavigate(`/products?big_category=1&category=3&product_type__in=${item.id}`)}} className='more_item'>
                  <p>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</p>
                  </div>)
            })}
             </div> 
             <div style={{display:hover_data['cat_4']?'flex':'none'}} className='more_big_list_two more_big_list'>
            {credit_type_data.map((item, key)=>{
                 return( <div onClick={()=>{editNavigate(`/products?big_category=1&category=4&itype__in=[${item.id}]`)}} className='more_item'>
                  <p>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</p>
                  </div>)
            })}
             </div> 
             <div style={{display:hover_data['cat_5']?'flex':'none'}} className='more_big_list_two more_big_list'>
            {cmtype_data.map((item, key)=>{
                 return( <div onClick={()=>{editNavigate(`/products?big_category=2&category=5&cmtype=${item.id}`)}} className='more_item'>
                  <p>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</p>
                  </div>)
            })}
             </div> 
            
             <div style={{display:hover_data['cat_6']?'flex':'none'}} className='more_big_list_two more_big_list'>
            {car_service_types_data.map((item, key)=>{
                 return( <div onClick={()=>{editNavigate(`/products?big_category=2&category=6&service_types__in=[${item.id}]`)}} className='more_item'>
                  <p>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</p>
                  </div>)
            })}
             </div> 
             <div style={{display:hover_data['cat_7']?'flex':'none'}} className='more_big_list_two more_big_list'>
            {car_product_type_data.map((item, key)=>{
                 return( <div onClick={()=>{editNavigate(`/products?big_category=2&category=7&product_type__in=${item.id}`)}} className='more_item'>
                  <p>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</p>
                  </div>)
            })}
             </div> 
             <div style={{display:hover_data['cat_8']?'flex':'none'}} className='more_big_list_two more_big_list'>
            {credit_type_data.map((item, key)=>{
                 return( <div onClick={()=>{editNavigate(`/products?big_category=2&category=8&itype__in=[${item.id}]`)}} className='more_item'>
                  <p>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</p>
                  </div>)
            })}
             </div> 
          </div>
          </li>
        </ul>
      </div>
       
        <div className='navbar_search'>
        {/* <SearchBox search={search} setsearch={setsearch}/> */}
        </div>
        <div className='navbar_box'>
        <div className={`navbar_search_box ${open_search?"open_search":''}`}>
            <input className='search_input' value={search} onChange={(e)=>setsearch(e.target.value)} type='text' placeholder={t("search")}/>
          <div onClick={goSearch} className='login_icon'>
          <LuSearch className='login_icon_svg' />
          </div>
          </div>
          <button onClick={()=>changeLang(t("lang")==='ru'?'uz':'ru')} className={`til_btn`}>{t("lang")}</button>
          
            {user===null?
          <Link to={"/login"} className='login_icon'>
          <FiUser />
          </Link>: <div className={`right_navbar_box ${isOpen?"open_right_box":''}`}>
               <button>
               <div className='user_box icon_nav' onClick={()=>{setisOpen(!isOpen)}}>
       <div className='user_avatar' style={{backgroundImage:`url(${user!==null && user.image!==null?user.image:avatar})`}} />
              <div className='user_text icon_nav'>
                <h3>{user!=null?user.first_name:''}</h3>
                <p>{user!=null?user.phone:''}</p>
              </div>
            </div>
               </button>
               <div className='right_list'>
                  <ul>
                  <li className='icon_nav'><Link className='drop_link' to={"/client"}><span><IoSettingsSharp /></span><p>{t("parametr")}</p></Link></li>
                  <li className='icon_nav'><Link className='drop_link' to={"/client/home/home"}><span><IoNewspaperOutline /></span><p>{t("my_adds")}</p></Link></li>
        {/* <li className='icon_nav'><span><IoBookmark /></span><p>{t("saved")}</p></li> */}
        {/* <li className='icon_nav'><span><FaMoneyBillWave /></span><p>{t("payments")}</p></li> */}
        <li className='icon_nav' onClick={logout}><span><IoLogOut /></span><p>{t("exit")}</p></li>
                  </ul>
               </div>
          </div>
          }
            
        </div>
    </div>
  )
}
